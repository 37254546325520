import React, { useState, useRef, useEffect } from "react";
import "../styles/screens/editorialManagement.css";
import img1 from '../assets/screens/img1.jpg';
import img2 from '../assets/screens/img2.jpg';
import img3 from '../assets/screens/img3.jpg';
import { Helmet } from "react-helmet";
import axios from "axios";
const EditorialManagement = () => {



const [data, setData] = useState(null);
useEffect(()=>{
  async function fetchTrackApiData() {
    const formData = new FormData();
    formData.append("sub_category","")
    
    try {
      const response = await axios.post(
        `https://app.xperttext.com/api/get-editorial-management`,
        formData,
        {
        headers: {
        "Content-Type": "multipart/form-data"
        },
        }
       
      );

      console.log(response.data)
      setData(response.data)
    } catch (error) {
      await console.error(error);
      // window.alert("Can't Assign Same Track Name")
    }
  }
  fetchTrackApiData();
},[1])

function openDetails(index){
  // console.log(index,data[index-1])
  localStorage.setItem("openDetailsId",index)
  window.open(`/editorial-management/${data[index-1].id}`, "_blank")
}
  return (
    <>
    <Helmet>   
        <title>
        Editorail Management
        </title>       
      </Helmet>
      <div style={{background:'#F7F7F7'}}>

    <div className="container" >

      <div className="EditorialHeading">
        Editorial Management
        <div style={{borderBottom:'3px solid rgba(31,83,140,1)',width:80,marginTop:8}}></div>
      </div>
      
      <div className="CardWrapper">
      {
        data && data.map((item,index)=>
        <>
       
       <div className="cardEditor" key={index} onClick={()=>openDetails(item.id)}>
              <div className="cardStyle d-flex ">
              {
                    item.photo &&
                <div className="postImage">
                  <img className="img-fluid" src={`https://app.xperttext.com/${item.photo}`} />
                  </div>
                   }
                <div className="postDetails">
                  {
                    item.name &&
                      <div className="postName" >{item.name}</div>
                  }
                  {
                    item.job_title &&
                      <div className="postProfession">{item.job_title}</div>
                  }
                  {
                    item.university &&
                      <div className="postUni">{item.university}</div>
                  }
                </div>
              </div>

              <div className="cardStyleDown">

              <div className="postArticleName">
                {
                  item.category &&
                      <i>
                         {item.category}
                      </i>  
                }
                    </div>
                    {
                      item.issn &&
                      <div className="postArticleName">{"("} {item.issn} {")"}</div>
                    }                
            </div>
              </div>
       
        </>
        )
      }
       </div>
    </div>
      </div>
    </>
  );
};

export default EditorialManagement;
