import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowRight, faBars} from "@fortawesome/free-solid-svg-icons";
import "../styles/screens/Journal.css";
import { Carousel,Button,Modal } from 'react-bootstrap'; 
import Logo from "../assets/components/Logo.jpg";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from 'react-router-dom';
const Journal = ({dispatch}) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [show, setShow] = useState(false);
    const { journalName } = useParams(); 
  const handleSelect = (selectedIndex, e) => {
    setActiveIndex(selectedIndex);
  };
  const [data, setData] = useState(null);
  const [slides, setSlides] = useState(null);
  const [isStateVisible, setIsStateVisible] = useState(false);
  const imageRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const imageRect = imageRef.current.getBoundingClientRect();
      setIsStateVisible(window.scrollY > imageRect.bottom);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => { 
       async function fetchTrackApiData() {
         const formData = new FormData();
         formData.append("slug",journalName)
         try {
           const response = await axios.post(
             `https://app.xperttext.com/api/view-sub-category-by-slug`,
             formData,
             {
             headers: {
             "Content-Type": "multipart/form-data"
             },
             }
            
           );
   
           console.log(response.data)

             setData(response.data);
             const slides = Object.entries(response.data.sections).map(([key, section]) => ({
              id: parseInt(key.split('_')[1], 10), 
              ...section, 
            }));
            setSlides(slides)
         } catch (error) {
           await console.error(error);
         }
       }
       fetchTrackApiData();
     }, [1]);

     const [headerData, setHeaderData] = useState(null);
     useEffect(() => { 
      async function fetchTrackApiData() {
        const formData = new FormData();
        formData.append("slug",journalName)
        try {
          const response = await axios.post(
            `https://app.xperttext.com/api/get-menu-by-sub-category-slug`,
            formData,
            {
            headers: {
            "Content-Type": "multipart/form-data"
            },
            }
           
          );
  
          console.log(response.data,"checkHeader")

          setHeaderData(response.data);
        } catch (error) {
          await console.error(error);
        }
      }
      fetchTrackApiData();
    }, [1]);

    const [openMenus, setOpenMenus] = useState({}); // Object to track open menus

  const handleClick = (menuItem) => {
    setOpenMenus({ ...openMenus, [menuItem.parentMenu.name]: !openMenus[menuItem.parentMenu.name] });
  };

const articles = [
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  },
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  },
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  },
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  },
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  },
  {
    articleName:'Oscillation of Fourth-Order Nonlinear Semi-Canonical Neutral Difference Equations via Canonical Transformations',
    articleAuthors:'P. Ganesan | G. Palani | E. Thandapani',
    articleType:'Research Article',
    articleDate:'MARCH 1,2024',
    articleBreif:'The authors present a new technique for transforming fourth-order semi-canonical nonlinear neutral difference equations into canonical form. This greatly simplifies the examination of the oscillation of solutions. Some new oscillation criteria are established by comparison with first-order delay difference equations...'
  }
]

   if(data === null){
    return(
      <>
        <div style={{height:2000,textAlign:'center',paddingTop:50}}>
          PAGE NOT FOUND!!
        </div>
      </>
    )
   }
  return (
    <>
    <Helmet>   
        <title>
        {data.meta_description} 
        </title>       
      </Helmet>
    {/* Journal Top Cont Start*/}
    <div className="journalTopCont" style={{position:'relative',backgroundImage:`url(https://app.xperttext.com/${data.main_image.replace(/^https?:\/\/[^\/]+\//, '')})`}}>
{/* Journal Header Start */}

{/* Big Screen Start */}
      <div className="d-none d-lg-flex justify-content-between align-items-center" style={{background:'#F7F7F7',position:'fixed',top:'4rem',width:'100%',zIndex:2,paddingLeft:8}}>

        <div className="journalHeading">
            {data && data.name &&
            <>
             {isStateVisible && <div>{data.name}</div>}
            </>
            }
        </div>
        <div className="d-flex align-items-center">
        {headerData != null && headerData.map((item, index) => (
        <div key={index}>
          {item.parentMenu.subMenus.length === 0 ? (
            <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
          ) : (
            <button
              className="journalMenuListItems"
              onClick={() => handleClick(item)}
            >
              <div className="d-flex align-items-center">
                <div>{item.parentMenu.name}</div>
                <div style={{ marginLeft: 5, marginTop: 4 }}>
                  {openMenus[item.parentMenu.name] ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                  )}
                </div>
              </div>
            </button>
          )}
        </div>
      ))}
      {Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
        isOpen && (
          <div key={menuName} className="dropDownStyle row">
            {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
              (subMenuItem, subIndex) => (
                <Link key={subIndex} to={subMenuItem.url} style={{ padding: '10px 40px' }} className="col-3">
                  {subMenuItem.name}
                </Link>
              )
            )}
          </div>
        )
      ))}
          
        </div>
       
      {/* {
        showAboutJournal &&
        <div className="dropDownStyle d-flex justify-content-around" >

                <div >
                    <div style={{color:'#282828',fontWeight:500}}>
                        Scope
                        <div style={{borderBottom:'2px solid #282828',width:40}}>
                        </div>
                    </div>
                    <div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Fiels cheif editor</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Mission & Scope</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Facts</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Journal sections</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Open access statement</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Copyright statement</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Quality</Link>
                        </div>
                    </div>
                </div>

                <div >
                <div style={{color:'#282828',fontWeight:500}}>
                       For Authors
                        <div style={{borderBottom:'2px solid #282828',width:80}}>
                        </div>
                    </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Why submit?</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Article types</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Author guidelines</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Editor guidelines</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Publishing fees</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Submission checklist</Link>
                        </div>
                        <div style={{padding: '15px 0px'}}>
                        <Link>Contact editorial office</Link>
                        </div>
                </div> 

        </div>
      } */}
      </div>
      
{/* Big Screen End */}

{/* Small Screen Start */}
<div className='showJump' style={{
    background: ' rgba(31,83,140,1)',
    padding: 8,
    position: 'fixed',top:'4rem',right:8}}>
    <Button  variant="primary" style={{width:'auto'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
    }}>
     <FontAwesomeIcon icon={faBars}/>
      </Button>
    </div>
<Modal style={{position: 'absolute',
  top: 0,
  left: 0}} show={show} 
onHide={() => setShow(false)}>
    <Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>
<div className="d-flex" >
<div>

         {headerData != null && headerData.map((item, index) => (
        <div key={index}>
          {item.parentMenu.subMenus.length === 0 ? (
            <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
          ) : (
            <button
              className="journalMenuListItems"
              onClick={() => handleClick(item)}
            >
              <div className="d-flex align-items-center">
                <div>{item.parentMenu.name}</div>
                <div style={{ marginLeft: 5, marginTop: 4 }}>
                  {openMenus[item.parentMenu.name] ? (
                    <FontAwesomeIcon icon={faAngleLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleRight} />
                  )}
                </div>
              </div>
            </button>
          )}
        </div>
      ))}
      
</div>

<div >
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
        isOpen && (
          <div key={menuName} className="smallDropDownStyle">
            {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
              (subMenuItem, subIndex) => (
                <div style={{padding: '10px 40px'}}>
                <Link key={subIndex} to={subMenuItem.url} className="col-3">
                  {subMenuItem.name}
                </Link>
                </div>
              )
            )}
          </div>
        )
      ))}

</div>
</div>
</Modal>
{/* Small Screen End */}

{/* Journal Header End */}


{/* Journal Img Cont Start */}
   <div className="imgHeading"    
 ref={imageRef}>
   {data && data.name &&
            <>
              {data.name}
            </>
            }
    </div>
    <div className="citationsCont d-flex">
        <div className="d-flex jusCitations">
        
          {data && data.impact_actor &&  
          <div className="singCit" style={{background:'rgba(40,40,40,.1)',backdropFilter:'blur(30px)'}}>
            <div className="singCitNumb">
              {data.impact_factor}
            </div>
            <div className="singCitName">
              Impact Factor
            </div>
          </div>
          }
        {
          data && data.cite_score &&
          <div className="singCit" style={{background:'rgba(40,40,40,.2)',backdropFilter:'blur(30px)'}}>
          <div className="singCitNumb">
              {data.cite_score}
            </div>
            <div className="singCitName">
              CiteScore
            </div>
          </div>
        }
        <div className="singCit" style={{background:'rgba(40,40,40,.3)',backdropFilter:'blur(30px)'}}>
        <div className="singCitNumb">
            2,278
           </div>
           <div className="singCitName">
            Citations
           </div>
        </div>
        {data && data.issn_number &&  
          <div className="singCit" style={{background:'rgba(40,40,40,.4)',backdropFilter:'blur(30px)'}}>
            <div className="singCitNumb">
              {data.issn_number}
            </div>
            <div className="singCitName">
              ISSN 
            </div>
          </div>
          }
        </div>
        <div onClick={()=>{window.location.href="https://app.xperttext.com/"}} className="showSmallSubmit singCit  align-items-center justify-content-center" style={{background:'#1db5c3',fontWeight:500,fontSize:'1rem'}}>
          <div > Submit <FontAwesomeIcon   icon={faArrowRight } /></div>
        </div>
    </div>
    
    


{/* Journal Img Cont End */}


    </div>
   
      {/* Journal Top Cont End*/}
      <div className="showBigSubmit align-items-center justify-content-center" style={{background:'#1db5c3',fontWeight:500,fontSize:'1rem'}}>
          <div> Submit <FontAwesomeIcon   icon={faArrowRight } /></div>
        </div>
{/* Corousel Start */}
{
  slides !== null &&
    <Carousel indicators={slides.length === 0 ? false : true} interval={slides.length === 0 ? null : 10000} activeIndex={activeIndex} onSelect={handleSelect}>
          {slides.map((slide,index) => (
            <Carousel.Item key={index} >
            <div className="slide d-lg-flex justify-content-around align-items-center">

              <h2 className="slideHeading">
                {slide.title}
              </h2>
              <div className="slideDesc">
              <div dangerouslySetInnerHTML={{ __html: slide.content }} />
                    <div className="slideLink">
                      <Link to={slide.link} style={{textDecoration:'none'}}> {slide.button}</Link> 
                      {console.log(slide.button,"btnn")}
                        {
                        
                          slide.button != null &&
                          <FontAwesomeIcon className="ml-2"   icon={faAngleRight} />
                        }
                        
                    </div>
              </div>
            </div>
          
            </Carousel.Item>
          ))}
        </Carousel>
}

{/* Corousel End */}
    
<div className="articlesCont">
   <div>
    <h2>
      Latest Articles
    </h2>
   </div>
   <ul className="ArticleCardWrapper">
     {
      articles.map((item,index)=>
        <li key={index}  className="ArticleCardEditor" style={{padding:0,borderRadius:8,position:'relative'}}>
          <div>
          <div style={{fontSize:11}} >
           <div className="singleArticleType px-3 pt-4">{item.articleType}</div>
           <div className="singleArticleDate px-3 ">{item.articleDate}</div>
          </div>
          <div className="singleArticleName px-3 pt-4">
            {item.articleName}
          </div>
          <div className="singleArticleAuthors px-3 pb-2">
            {item.articleAuthors}
          </div>
          <div className="px-3 pb-4 mb-4">
            {item.articleBreif}
          </div>
          <div style={{bottom:8,position:'absolute',borderTop:'1px solid #f0f0f0',color:'#00A4B7'}} className="d-flex justify-content-between w-100 align-items-center px-3 py-1">
            <div>
            Read the full article
            </div>
            <div>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
            
          </div>
          </div>
        </li>
      )
     }
   </ul>
</div>
    </>
  );
};

export default connect() (Journal);
