import React, { useState, useEffect } from 'react';
import '../styles/components/Footer.css';
import Logo from "../assets/components/Logo.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEnvelopeOpen, faMapMarkerAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import Send from '../assets/components/telegram.png';
import facebookImg from '../assets/components/facebook.png';
import twitterImg from '../assets/components/twitter.png';
import googleImg from '../assets/components/googleplus.png';
import instaImg from '../assets/components/instagram.png';
import youtubeImg from '../assets/components/youtube.png';
import linkedinImg from '../assets/components/linkedin.png';
import axios from 'axios';
const Footer = () => {
    const [data, setData] = useState(null); 
    const [submitted, setSubmitted] = useState(false);
    const [openError, setopenError] = useState(false);
    const [formData, setformData] = useState({});
   useEffect(() => {
       
        async function fetchTrackApiData() {
          const formData = new FormData();
          formData.append("lang","en")
          
          try {
            const response = await axios.post(
              `https://app.xperttext.com/api/footer-menus`,
              formData,
              {
              headers: {
              "Content-Type": "multipart/form-data"
              },
              }
             
            );
    
            console.log(response.data)

              setData(response.data);
          } catch (error) {
            await console.error(error);
            // window.alert("Can't Assign Same Track Name")
          }
        }
        fetchTrackApiData();
      }, [1]);

      const handleSubmit = async (event) => {
        event.preventDefault();
       console.log(formData['entry.1313979256'] !== "" )
      
       const form = document.getElementById("footer-form");
            form.querySelectorAll("input").forEach(input => input.value = "");
        if(formData['entry.596556922'] !== "" ){
    
          fetch("https://docs.google.com/forms/d/e/1FAIpQLSdi-1_Z1sYM2Xo75cwUKz3Gmo6Kvdsfk6a35S1sNFitUyRI4A/formResponse", {
          method: "POST",
          body: new URLSearchParams(formData),
          mode: "no-cors" // Not recommended for production
          })
          .then(() => {
            console.log("Form submission attempted (CORS disabled)");
            const form = document.getElementById("footer-form"); // Replace with your form ID
            form.querySelectorAll("input").forEach(input => input.value = "");
            setSubmitted(true)
          })
          .catch((error) => {
            console.error("Form submission error:", error);
          });
        }
        else{
        setopenError(true)
        }
        };

        function  handleChange(event){
        
          setformData({
            ...formData,
            [event.target.name]: event.target.value, });
          };
  return (
    <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-sm-5 pb-sm-5">
            {
                data && data.fellow_us && 
                     <div className="d-sm-flex justify-content-between">
                                {
                                    data.fellow_us.contents.find_us && data.fellow_us.contents.find_us.title && data.fellow_us.contents.find_us.content &&
                                        <div className="d-flex d-sm-block justify-content-center mb-4 mb-sm-0">
                                            <div className="single-cta">
                                            <FontAwesomeIcon icon={faMapMarkerAlt}  />
                                            
                                                <div className="cta-text">
                                                    <h4>{data.fellow_us.contents.find_us.title}</h4>
                                                    <span>{data.fellow_us.contents.find_us.content}</span>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    data.fellow_us.contents.call_us && data.fellow_us.contents.call_us.content && data.fellow_us.contents.call_us.title &&
                                        <div className="d-flex d-sm-block justify-content-center mb-4 mb-sm-0">
                                            <div className="single-cta mr-4 mr-sm-0">
                                            <FontAwesomeIcon icon={faPhone}  />
                                                <div className="cta-text">
                                                    <h4>{data.fellow_us.contents.call_us.title}</h4>
                                                    <span>{data.fellow_us.contents.call_us.content}</span>
                                                </div>
                                            </div>
                                        </div>
                                }
                                {
                                    data.fellow_us.contents.mail_us && data.fellow_us.contents.mail_us.title && data.fellow_us.contents.mail_us.content &&
                                        <div className="d-flex d-sm-block justify-content-center mb-4 mb-sm-0">
                                            <div className="single-cta">
                                            <FontAwesomeIcon icon={faEnvelopeOpen}  />
                                                <div className="cta-text">
                                                    <h4>{data.fellow_us.contents.mail_us.title}</h4>
                                                    <span>{data.fellow_us.contents.mail_us.content}</span>
                                                </div>
                                            </div>
                                        </div>
                                }
                     </div>
                        
            }
            </div>
            <div className="footer-content pt-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <img  src={Logo}  className="img-fluid"  />
                            </div>
                            <div className="footer-text">
                                {
                                    data && data.about_us &&
                                        <p>{data.about_us}</p>
                                }
                            </div>
                            <div className="footer-social-icon">
                            <div className="footer-widget-heading">
                                <h3>Follow us</h3>
                            </div>
                            { data && data.social_media &&
                                <>
                                {
                                    data.social_media.facebook && data.social_media.facebook.content && data.social_media.facebook.title &&
                                    <a href={data.social_media.facebook.content}>
                                    <img  src={facebookImg}  />
                                    </a>
                                }

                                {
                                    data.social_media.instagram && data.social_media.instagram.content && data.social_media.instagram.title &&
                                    <a href={data.social_media.instagram.content}>
                                    <img  src={instaImg}  />
                                    </a>
                                }

                                {
                                    data.social_media.twitter && data.social_media.twitter.content && data.social_media.twitter.title &&
                                    <a href={data.social_media.twitter.content}>
                                    <img  src={twitterImg}   />
                                    </a>
                                }

                                {
                                    data.social_media.linkedin && data.social_media.linkedin.content && data.social_media.linkedin.title &&
                                    <a href={data.social_media.linkedin.content}>
                                    <img  src={linkedinImg}  />
                                    </a>
                                }
                                {
                                    data.social_media.pinterest && data.social_media.pinterest.content && data.social_media.pinterest.title &&
                                    <a href={data.social_media.pinterest.content}>
                                    <img  src={linkedinImg}  />
                                    </a>
                                }


                                
                                
                                </>
                            }
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30 mt-4">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                {
                                    data && data.useful_links &&
                                    <>
                                       {
                                        data.useful_links.map((item,index)=>
                                            <li key={index}><a href={item.content}>{item.title}</a></li>
                                        )
                                       }
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                    {
                        data && data.subscribe_section && data.subscribe_section.content && data.subscribe_section.title &&
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-50 mt-4">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading">
                                        <h3>{data.subscribe_section.title}</h3>
                                    </div>
                                    <div className="footer-text mb-25">
                                        <p>{data.subscribe_section.content}</p>
                                    </div>
                                    <div className="subscribe-form">
                                        <form id="footer-form" onSubmit={handleSubmit} style={{display:'flex'}}>
                                            <input required  onChange={handleChange} name="entry.1313979256" type="email" 
                                            placeholder={data.subscribe_section.placeholder} />
                                            <button type="submit">
                                            {/* <FontAwesomeIcon icon={fabTele}  /> */}
                                            <img  src={Send} width={23}  />
                                        </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="">
                <div className="row">
                    {
                        data && data.copyright &&
                            <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                <div className="copyright-text">
                                    <p>{data.copyright}</p>
                                </div>
                            </div>
                    }
                    <div className="col-xl-6 col-lg-6 pt-2  pt-sm-0">
                        {
                            data && data.footer_menu &&
                                <div className="footer-menu" style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                    <ul>
                                        {
                                            data.footer_menu.map((item,index)=>
                                                <li key={index}><a href={item.content}>{item.title}</a></li>
                                            )
                                        }
                                    </ul>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
