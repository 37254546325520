import React,{useState,useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import '../styles/components/Header.css';
import Logo from "../assets/components/Logo.jpg";
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart, faBars } from '@fortawesome/free-solid-svg-icons';
const Header = () => {
  const [isOpenList, setIsOpenList] = useState(false);
  const [show, setShow] = useState(false);
  const toggleOpenList = () => {
    setIsOpenList(!isOpenList);
  };
  const [data,setData] = useState([])
  useEffect(() => {
   
    async function fetchTrackApiData() {
      const formData = new FormData();
      formData.append("lang","en")
      // https://xperttext.ourdev.in
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/header-menus`,
          formData,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );

        await console.log(response.data);
        setData(response.data)
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);

 
  return (
    <div className='headerDiv headerStyle show'>
      
    <nav className="header">
      <ul className="nav-list">
        <li>
          <NavLink exact to="/" className="nav-link" activeClassName="active" style={{border:'transparent'}}>
            <img style={{ height: "50px" }} src={Logo} />
          </NavLink>
        </li>

        </ul>

        <ul className="nav-list">
        {
                          data.map((item,index)=>(
                            <li key={index}>
                              {
                                index != data.length-1 ?
                                <NavLink exact to={item.content} className="nav-link" activeClassName="active">
                {item.title}
              </NavLink>
              :
              <NavLink exact to={item.content} className="nav-link" activeClassName="active">
              <button  className='contactFormInputButton' style={{padding:'5px 15px'}}>
          {item.title}
          </button> 
          </NavLink>
              
                              }
              
            </li>
                          ))
                        }
         
       
      </ul>
    </nav>
    <div className="showBars">
          <ul>
            <li>
              <NavLink exact to="/" className="nav-link" activeClassName="active" style={{border:'transparent'}}>
                <img style={{ height: "50px" }} src={Logo} />
              </NavLink>
            </li>
          </ul>
          <div className='showJump' style={{right: 0,
    padding: 20,
    position: 'fixed',top:0,color:'black'}}>
    <Button  variant="primary" style={{float:'right',color:'black',fontWeight:'bold'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
    }}>
     Menu
      </Button>
    </div>
          

        </div>

        <Modal  show={show} 
onHide={() => setShow(false)}>
      <Modal.Header closeButton>  <img style={{ height: "50px" }} src={Logo} /></Modal.Header>

 <ul className="openList open">
           {
                          data.map((item,index)=>(
                            <li key={index} onClick={()=>{
                              setShow(false);
                            }}>
              <NavLink exact to={item.content} style={{textAlign:'center'}} className="nav-link" activeClassName="active">
                {item.title}
              </NavLink>
            </li>
                          ))
                        }
          </ul>
</Modal>
    </div>

  );
};

export default Header;
