import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams } from 'react-router-dom';
import blogImg1 from '../assets/screens/blogImg1.jpg';
import blogImg2 from '../assets/screens/blogImg2.jpg';
import blogImg3 from '../assets/screens/blogImg4.jpg';
import blogImg4 from '../assets/screens/blogImg4.jpg';
import blogImg5 from '../assets/screens/blogImg5.jpg';
import '../styles/screens/singleBlog.css';
import axios from "axios";
const SingleBlog = ({dispatch}) => {
  const { blogName } = useParams();
  const [blogDetails, setBlogDetails] = useState([]);
  const [featuredBlogData, setfeaturedBlogData] = useState([]);
  
  // const featuredBlogData=[
  //   {
  //     'blogImg':blogImg1,
  //     'tag':'Fitness',
  //     'blogName':'What is Stamina and How to Increase Yours',
  //     'blogDesc':`We've all heard the term but it's likely that many of us don't know what it really means. Here's our breakdown of all things ‘stamina' - what it is, what it isn't, and how to improve yours!`,
  //     'blogDate':'Aug 10, 2022',
  //     'id':1
  //   },,
  //   {
  //     'blogImg':blogImg2,
  //     'tag':'Stamina',
  //     'blogName':'What is Stamina and How to Increase Yours',
  //     'blogDesc':`We've all heard the term but it's likely that many of us don't know what it really means. Here's our breakdown of all things ‘stamina' - what it is, what it isn't, and how to improve yours!`,
  //     'blogDate':'Aug 10, 2022',
  //     'id':1
  //   },
  //   {
  //     'blogImg':blogImg3,
  //     'tag':'Workouts',
  //     'blogName':'What is Stamina and How to Increase Yours',
  //     'blogDesc':`We've all heard the term but it's likely that many of us don't know what it really means. Here's our breakdown of all things ‘stamina' - what it is, what it isn't, and how to improve yours!`,
  //     'blogDate':'Aug 10, 2022',
  //     'id':1
  //   },
  // ]
  useEffect(()=>{
    async function fetchTrackApiData() {
      const formData = new FormData();
      formData.append("slug",blogName)
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-blog-by-slug`,
          formData,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        setBlogDetails(response.data)
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  },[1]);
  function formatISODate(dateString) {
    const date = new Date(dateString); // Create a Date object from the ISO string
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options); // Format according to options
  }
  useEffect(()=>{
    async function fetchTrackApiData() {
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-featured-blogs`,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        await console.log(response.data);
        setfeaturedBlogData(response.data)
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  },[1]);
return (
    <>
    <Helmet>   
        <title>
       {blogDetails.slug}
        </title>       
      </Helmet>
    <div className="singleBlogImg" style={{backgroundImage:`url(https://app.xperttext.com/${blogDetails.image1})`}}>
      {/* <div className="singleBlogTag">
        {blogDetails.name}
      </div> */}
      <h1 className="singleBlogName">
        {blogDetails.name}
      </h1>
      <div className="singleBlogDate">
      {formatISODate(blogDetails.published_at)}
      </div>
    </div>
    <div className="singleBlogContainer d-lg-flex justify-content-between">
    <div className="singleBlogdescPart" dangerouslySetInnerHTML={{ __html: blogDetails.content }} />
    <div className="blongsSide">
      <div>

    <div>
          <h4 style={{ fontWeight: 'bold' }}>
            Featured
          </h4>
          <div className="headerBorder"></div>
          </div>
      {
        featuredBlogData.map((item,index)=>(
          <Link to={`/blog/${item.slug}`} style={{textDecoration:'none',color:'black',cursor:'pointer'}} key={index} className=" d-flex justify-content-around mb-2" >
            <div>
                <img style={{width:100,borderRadius:8}} src={`https://app.xperttext.com/${item.image1}`} />
            </div>
            <div style={{marginLeft:20}}>
              <div style={{fontWeight:'bold'}}>
                {item.name}
              </div>
              <div style={{fontSize:11,color:'#777'}}>
                {formatISODate(item.created_at)}
              </div>
            </div>
        </Link>
        ))
      }
      </div>

      <div className="mt-3">
        <div>
          <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: 'bold' }}>
            Tags
          </h4>
          </div>
          <div className="headerBorder"></div>
        </div>
        <div className="row" style={{ rowGap: 5, columnGap: 24,width: '100%' }}>
          {featuredBlogData.map((item,index)=>(
             <button key={index} className="col-3 tagStyle">
             {item.category.name}
           </button>
          ))}
        </div>
      </div>
    </div>
    
    </div>
    </>
  );
};

export default connect() (SingleBlog);
