import React, { useState, useRef, useEffect } from "react";
import "../styles/screens/postDetails.css";

import img1 from '../assets/screens/img1.jpg';
import img2 from '../assets/screens/img2.jpg';
import img3 from '../assets/screens/img3.jpg';

import { Tabs, Tab } from "react-bootstrap";
import { Helmet } from "react-helmet";
import axios from "axios";
const PostDetails = () => {
const [currData,setcurrData] = useState(null);
const [customActiveTab, setcustomActiveTab] = useState("1");

    const JSON = [
        {
          img:img1,
          name:"Ren Ke Li",
          designation:"PhD",
          profession:"Professor of Surgery",
          university:"University of Toronto, Toronto, ON, Canada",
          phone:"416-581-7492",
          eMail:"renkeli@uhnres.utoronto.ca",
          articleName:"Cell, Stem cells and Regenerative Medicine",
          articleNumber:"ISSN 2472-6990",
          researchInterest:`Cell transplantation into damaged myocardial tissue to regenerate myocardium and restore heart function
          Tissue engineering to create a muscle graft using stem cells in combination with biomaterials for repair of cardiac defects. He has used stem cells
          Gene therapy and tissue engineering technique to regenerate and rejuvenate heart for restore heart function
          `,
          biography:"Dr. Ren-Ke Li is a Professor of Medicine in the Department of Surgery, Division of Cardiac Surgery at the University of Toronto. Dr. Li is also a Senior Scientist at the Toronto General Research Institute, University Health Network. He is the Canada Research Chair in Cardiac Regeneration.Dr. Li has been on the forefront in the field of cell transplantation and tissue engineering. In 1996, he published the first demonstration that cells transplanted into myocardial scar tissue improved heart function. Over 25 years his research group has defined muscle cell transplantation for Cardiac Repair, followed by stem cell transplantation for Cardiac Regeneration. Both cell repair and regeneration technologies have been translated to clinical application. Since the patients with heart failure are aged population, currently, his research group is attempting to determine the mechanisms by which transplanted cells exert their beneficial effects by Rejuvenation of aged stem cells and aged recipients. Clarifying these mechanisms of Repair, Regeneration and Rejuvenation will allow them to develop the “next generation” of cell therapy for restoration of heart function of aged patients. Dr. Li has published 232 peer-reviewed papers."
        },
        {
          img: img2,
          name:"Clinton Jones",
          designation:"PhD",
          profession:"Professor of Infectious Diseases",
          university:"Oklahoma State University, Stillwater, Oklahoma, USA",
          phone:"405-744-1842",
          eMail:"clint.jones10@okstate.edu",
          articleName:"Journal of Emerging Diseases and Virology",
          articleNumber:"ISSN 2473-1846"
        },
        {
          img: img3,
          name:"Toru Ouchi",
          designation:"PhD",
          profession:"Professor of Cancer Genetics",
          university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
          phone:"716-845-7173",
          eMail:"Toru.Ouchi@RoswellPark.org",
          articleName:"International Journal of Cancer Research and Molecular Mechanisms",
          articleNumber:"ISSN 2381-3318"
        },
        {
          img:img1,
          name:"Ren Ke Li",
          designation:"PhD",
          profession:"Professor of Surgery",
          university:"University of Toronto, Toronto, ON, Canada",
          phone:"416-581-7492",
          eMail:"renkeli@uhnres.utoronto.ca",
          articleName:"Cell, Stem cells and Regenerative Medicine",
          articleNumber:"ISSN 2472-6990"
        },
        {
          img: img2,
          name:"Clinton Jones",
          designation:"PhD",
          profession:"Professor of Infectious Diseases",
          university:"Oklahoma State University, Stillwater, Oklahoma, USA",
          phone:"405-744-1842",
          eMail:"clint.jones10@okstate.edu",
          articleName:"Journal of Emerging Diseases and Virology",
          articleNumber:"ISSN 2473-1846"
        },
        {
          img: img3,
          name:"Toru Ouchi",
          designation:"PhD",
          profession:"Professor of Cancer Genetics",
          university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
          phone:"716-845-7173",
          eMail:"Toru.Ouchi@RoswellPark.org",
          articleName:"International Journal of Cancer Research and Molecular Mechanisms",
          articleNumber:"ISSN 2381-3318"
        },
        {
          img:img1,
          name:"Ren Ke Li",
          designation:"PhD",
          profession:"Professor of Surgery",
          university:"University of Toronto, Toronto, ON, Canada",
          phone:"416-581-7492",
          eMail:"renkeli@uhnres.utoronto.ca",
          articleName:"Cell, Stem cells and Regenerative Medicine",
          articleNumber:"ISSN 2472-6990"
        },
        {
          img: img2,
          name:"Clinton Jones",
          designation:"PhD",
          profession:"Professor of Infectious Diseases",
          university:"Oklahoma State University, Stillwater, Oklahoma, USA",
          phone:"405-744-1842",
          eMail:"clint.jones10@okstate.edu",
          articleName:"Journal of Emerging Diseases and Virology",
          articleNumber:"ISSN 2473-1846"
        },
        {
          img: img3,
          name:"Toru Ouchi",
          designation:"PhD",
          profession:"Professor of Cancer Genetics",
          university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
          phone:"716-845-7173",
          eMail:"Toru.Ouchi@RoswellPark.org",
          articleName:"International Journal of Cancer Research and Molecular Mechanisms",
          articleNumber:"ISSN 2381-3318"
        },
        {
          img:img1,
          name:"Ren Ke Li",
          designation:"PhD",
          profession:"Professor of Surgery",
          university:"University of Toronto, Toronto, ON, Canada",
          phone:"416-581-7492",
          eMail:"renkeli@uhnres.utoronto.ca",
          articleName:"Cell, Stem cells and Regenerative Medicine",
          articleNumber:"ISSN 2472-6990"
        },
        {
          img: img2,
          name:"Clinton Jones",
          designation:"PhD",
          profession:"Professor of Infectious Diseases",
          university:"Oklahoma State University, Stillwater, Oklahoma, USA",
          phone:"405-744-1842",
          eMail:"clint.jones10@okstate.edu",
          articleName:"Journal of Emerging Diseases and Virology",
          articleNumber:"ISSN 2473-1846"
        },
        {
          img: img3,
          name:"Toru Ouchi",
          designation:"PhD",
          profession:"Professor of Cancer Genetics",
          university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
          phone:"716-845-7173",
          eMail:"Toru.Ouchi@RoswellPark.org",
          articleName:"International Journal of Cancer Research and Molecular Mechanisms",
          articleNumber:"ISSN 2381-3318"
        }
      ]


useEffect(()=>{
  async function fetchTrackApiData() {
    const formData = new FormData();
    formData.append("id",localStorage.getItem("openDetailsId"))
    
    try {
      const response = await axios.post(
        `https://app.xperttext.com/api/get-editorial-member`,
        formData,
        {
        headers: {
        "Content-Type": "multipart/form-data"
        },
        }
       
      );

      console.log(response.data)
      setcurrData(response.data)
    } catch (error) {
      await console.error(error);
      // window.alert("Can't Assign Same Track Name")
    }
  }
  fetchTrackApiData();
},[1])

  return (
    <>
    
    
      {currData &&
      <>
       <Helmet>   
        <title>
        {currData.name}
        </title>       
      </Helmet>
      {
        currData.category &&

          <div className="articleHeading">
            {currData.category}
          </div>
      }
        <div className="container">
        <div className="d-flex">
          {
            currData.photo &&
                    <div className="postImage col-1-4"><img style={{width:153,height:153}} src={`https://app.xperttext.com/${currData.photo}`}  /></div>
          }
                    <div className="singlepostDetails pl-5">
                      {
                        currData.name &&
                        <div className="singlepostName" style={{fontWeight:'bold'}}>{currData.name}</div>
                      }
                      {
                        currData.job_title &&
                        <div style={{fontWeight:'bold'}}>{currData.job_title}</div>
                      }
                      {
                        currData.university &&
                        <div>{currData.university}</div>
                      }
                      {
                        currData.phone &&
                          <div>
                          <span className="postPE">Phone:</span> {currData.phone} 
                          </div>
                      }
                      {
                        currData.email &&
                          <div>
                          <span className="postPE">E-mail:</span> {currData.email} 
                          </div>
                      }
                    </div>
                  </div>
          <Tabs className="mt-3" fill >
          {
                  currData.education.content &&
          <Tab eventKey="education" title="Education" style={{border:'1px solid #E7EAED'}}>
            <div className="p-3">
                <div className="tabContentHeading mb-3">
                    Education
                </div>
              
                    <div>
                        <div  dangerouslySetInnerHTML={{ __html: currData.education.content }} />
                    </div>
                
            </div>
          </Tab>
        }
         {
                  currData.biography.content &&
          <Tab eventKey="biography" title="Biography" style={{border:'1px solid #E7EAED'}}>
          <div className="p-3">
                <div className="tabContentHeading mb-3">
                    Biography
                </div>
               
                    <div>
                    <div  dangerouslySetInnerHTML={{ __html: currData.biography.content }} />
                    </div>
                
            </div>
          </Tab>
        }
        {
          currData.research_interest.content &&
            <Tab eventKey="researchInterest" title="Research Interest" style={{border:'1px solid #E7EAED'}}>
            <div className="p-3">
                  <div className="tabContentHeading mb-3">
                      Research Interest
                  </div>
                  <div>
                  <div  dangerouslySetInnerHTML={{ __html: currData.research_interest.content }} />

                  </div>
              </div>
            </Tab>
        }
        {
          currData.professional_activities.content &&
          <Tab eventKey="professionalActivities" title="Professional Activities" style={{border:'1px solid #E7EAED'}}>
          <div className="p-3">
                <div className="tabContentHeading mb-3">
                    Professional Activities
                </div>
                <div>
                <div  dangerouslySetInnerHTML={{ __html: currData.professional_activities.content }} />

                </div>
            </div>
          </Tab>
        }
        {
          currData.publications.content &&
            <Tab eventKey="publications" title="Publications" style={{border:'1px solid #E7EAED'}}>
            <div className="p-3">
                  <div className="tabContentHeading mb-3">
                      Publications
                  </div>
                  <div>
                  <div  dangerouslySetInnerHTML={{ __html: currData.publications.content }} />

                  </div>
              </div>
            </Tab>
        }
        </Tabs>
                
        </div>
      </>
      }
      
      
    </>
  );
};

export default PostDetails;
