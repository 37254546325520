import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import '../styles/screens/Pages.css';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from "axios";   
import Logo from "../assets/components/Logo.jpg";
import { Helmet } from 'react-helmet';

const Pages = () => { 
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
  useEffect(() => {
   
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        
        setIsOpenList(false);
        setIsOpenArticleList(false);
        setshowJOverViewSmall(false);
        setshowIssuesSmall(false);
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
        const element = document.getElementById('paraside')
        if(element){
            element.style.width = "75%"
        }
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
     
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
    return scrollDirection;
  };

  const { pageName } = useParams(); 
  const [data, setData] = useState(null); 
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
       
        async function fetchTrackApiData() {
          const formData = new FormData();
          formData.append("slug",pageName)
          
          try {
            const response = await axios.post(
              `https://app.xperttext.com/api/view-page-by-slug`,
              formData,
              {
              headers: {
              "Content-Type": "multipart/form-data"
              },
              }
             
            );
    
              console.log(response.data)
            const newHeadings = response.data.headings.filter(
                (heading) => heading.tag !== "h1"
              );
              // content.replace(/^\d+\.\s*/, '').toLowerCase().replace(/\s+/g, '-')
              newHeadings.forEach(heading => {
                heading.Text = heading.content;
                heading.id = heading.id;
                delete heading.content;
                delete heading.tag;
              });

              setData({ ...response.data, headings: newHeadings });
            // setData(response.data);
            setIsLoading(false);
          } catch (error) {
            await console.error(error);
            // window.alert("Can't Assign Same Track Name")
          }
        }
        fetchTrackApiData();
      }, [1]);

  const scrollToSection = (id,index) => {
  //  console.log(id)
   setShow(false)
    const element = document.getElementById(id);
 console.log(element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    const links = document.getElementsByClassName('articleContents');

  for (const link of links) {
    link.style.color = 'black'; 
    link.style.borderLeft = "4px solid #00A4B7"
    link.style.background = "#FFFFFF"
  }

  const links1 = document.getElementsByClassName('articleContents1');
    
  for (const link of links1) {
    link.style.background = "#FFFFFF"
  }

  // Set color of clicked link to blue
  const setStyleItem = document.getElementById(`active${index}`);
  // setStyleItem.style.borderLeft = "4px solid #00A4B7"
   

   const setStyleItemItem = document.getElementById(`active1${index}`)
  //  setStyleItemItem.style.background = "#F2F2F2";
   
  }

  const scrollDirection = useScrollDirection();
  const [isOpenList, setIsOpenList] = useState(false);
  const [showJOverViewSmall, setshowJOverViewSmall] = useState(false);
  const [showIssuesSmall, setshowIssuesSmall] = useState(false);
  const [isOpenArticleList, setIsOpenArticleList] = useState(false);


  if(isLoading){
    return(
        <>
          <div style={{height:2000,textAlign:'center',paddingTop:50}}>
            PAGE NOT FOUND
          </div>
        </>
    )
  }
 
  return (
    <>
    {
  data &&
     <Helmet>   
  <title>
     {data.meta_description} 
  </title>       
      </Helmet>
    }
    <div className='showJump' style={{right: 0,
    background: ' rgba(31,83,140,1)',
    padding: 10,
    position: 'fixed',top:scrollDirection === "down" ? 5 : '4rem',right:5}}>
    <Button  variant="primary" style={{float:'right'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
    }}>
     Jump to :
      </Button>
    </div>
<Modal style={{right:0,top:scrollDirection === "down" ? 45 :108,width:'100%'}} show={show} 
onHide={() => setShow(false)}>
        <Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>

<div>
        
<div style={{overflowY:'auto'}}>

          
<div className='articleSmallMenu'>
{
  data &&
  <>
  {
 data.headings.map((item,index)=>
  
  <Link key={index} className={`active${index}`} onClick={() => scrollToSection(`${item.id}`,`${index}`)}>
   
   {item.Text}</Link>
 
 )
 }
  </>
}
</div>

   </div>
   </div>
</Modal>


    <div className='d-flex p-0 p-sm-4' style={{flexFlow:'wrap'}}>
      
    <div className='pagesParaSide'>
      
      {/* {console.log(data.content)} */}
      {
        data &&
        <div className={` ${ scrollDirection === "down" ? "paraSidehide" : "paraSideshow"}`} dangerouslySetInnerHTML={{ __html: data.content }} />

      }
    </div>
    <div className="pagenavigation">
    <div style={{paddingLeft:18,fontWeight:'bold'}}>Jump To:</div>
         <div style={{borderBottom:'2px solid #305F8B',width:50,marginLeft:27,marginBottom:10,marginTop:5,borderRadius:13}}></div>
            <div style={{position: 'sticky',padding:20,
            top:150,paddingTop:0,overflow: 'auto',
            height: '100%'}}>
             
<div >

          
         <div className='articleMenu' style={{border:"none"}}>

       {
        data &&
        <>
        {
      data.headings.map((item,index)=>
        
        <Link key={index} id={`active${index}`} className='articleContents' onClick={() => scrollToSection(`${item.id}`,`${index}`)} style={{borderLeft: "4px solid #305F8B",paddingLeft:20}}>
           <div className='articleContents1' id={`active1${index}`}>
{item.Text}
</div>
          </Link>

      )
    }
    
        </>
       }
         </div>
        
            </div>
</div>
         
    </div>
    </div>
      <div>
          
      </div>



    
    </>
    
  );
}

export default Pages;



{/* <i className="fa fa-angle-up"></i> */}