import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import for handling route changes

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
  }, [location]); // Re-run effect on location change

  return null; // Empty component, rendered for side effect
};

export default ScrollToTop;