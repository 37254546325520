import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import '../styles/screens/Journals.css';
import { Modal, Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from "axios";   
import Logo from "../assets/components/Logo.jpg";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faLink,faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";

const JournalPages = () => { 
  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);
  
  useEffect(() => {
   
      let lastScrollY = window.scrollY;
  
      const updateScrollDirection = () => {
        
       
        const scrollY = window.scrollY;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
        const element = document.getElementById('paraside')
        if(element){
            element.style.width = "75%"
        }
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
     
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }
    }, [scrollDirection]);
    return scrollDirection;
  };

  const { pageName } = useParams(); 
  const [data, setData] = useState(null); 
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  

  useEffect(() => {
       
        async function fetchTrackApiData() {
          const formData = new FormData();
          formData.append("slug",pageName)
             formData.append("sub_category_slug",journalName)
          
          try {
            const response = await axios.post(
              `https://app.xperttext.com/api/sub-category-page_by-slug`,
              formData,
              {
              headers: {
              "Content-Type": "multipart/form-data"
              },
              }
             
            );
    
              console.log(response.data)
            const newHeadings = response.data.headings.filter(
                (heading) => heading.tag !== "h1"
              );
              // content.replace(/^\d+\.\s*/, '').toLowerCase().replace(/\s+/g, '-')
              newHeadings.forEach(heading => {
                heading.Text = heading.content;
                heading.id = heading.id;
                delete heading.content;
                delete heading.tag;
              });

              setData({ ...response.data, headings: newHeadings });
            // setData(response.data);
            setIsLoading(false);
          } catch (error) {
            await console.error(error);
            // window.alert("Can't Assign Same Track Name")
          }
        }
        fetchTrackApiData();
      }, [1]);

      const [data2, setData2] = useState(null);
      const { journalName } = useParams();
    
      useEffect(() => { 
           async function fetchTrackApiData() {
             const formData = new FormData();
             formData.append("slug",journalName)
             try {
               const response = await axios.post(
                 `https://app.xperttext.com/api/view-sub-category-by-slug`,
                 formData,
                 {
                 headers: {
                 "Content-Type": "multipart/form-data"
                 },
                 }
                
               );
       
               console.log(response.data)
    
                 setData2(response.data);
             } catch (error) {
               await console.error(error);
             }
           }
           fetchTrackApiData();
         }, [1]);

  const scrollToSection = (id,index) => {
  //  console.log(id)
   setShow(false)
   
    const element = document.getElementById(id);
 console.log(element)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    const links = document.getElementsByClassName('articleContents');

  for (const link of links) {
    link.style.color = 'black'; 
    link.style.borderLeft = "4px solid #00A4B7"
    link.style.background = "#FFFFFF"
  }

  const links1 = document.getElementsByClassName('articleContents1');
    
  for (const link of links1) {
    link.style.background = "#FFFFFF"
  }

  // Set color of clicked link to blue
  const setStyleItem = document.getElementById(`active${index}`);
  // setStyleItem.style.borderLeft = "4px solid #00A4B7"
   

   const setStyleItemItem = document.getElementById(`active1${index}`)
  //  setStyleItemItem.style.background = "#F2F2F2";
   
  }

  const scrollDirection = useScrollDirection();
  const [headerData, setHeaderData] = useState(null);
useEffect(() => { 
 async function fetchTrackApiData() {
   const formData = new FormData();
   formData.append("slug",journalName)
   try {
     const response = await axios.post(
       `https://app.xperttext.com/api/get-menu-by-sub-category-slug`,
       formData,
       {
       headers: {
       "Content-Type": "multipart/form-data"
       },
       }
      
     );

     console.log(response.data,"checkHeader")

     setHeaderData(response.data);
   } catch (error) {
     await console.error(error);
   }
 }
 fetchTrackApiData();
}, [1]);

const [openMenus, setOpenMenus] = useState({}); // Object to track open menus

const handleClick = (menuItem) => {
setOpenMenus({ ...openMenus, [menuItem.parentMenu.name]: !openMenus[menuItem.parentMenu.name] });
};


  if(isLoading){
    return(
        <>
          <div style={{height:2000,textAlign:'center',paddingTop:50}}>
            PAGE NOT FOUND
          </div>
        </>
    )
  }
 
  return (
    <>
    {
  data &&
     <Helmet>   
  <title>
     {data.meta_description} 
  </title>       
      </Helmet>
    }

{/* Journal Header Start */}

{/* Big Screen Start */}
<div className="d-none d-lg-flex justify-content-between align-items-center" style={{background:'#F7F7F7',position:'fixed',top:'4rem',width:'100%',zIndex:2,paddingLeft:8}}>

<div className="journalHeading">
    {data2 && data2.name &&
    <>
      {data2.name}
    </>
    }
</div>
<div className="d-flex align-items-center">
{headerData != null && headerData.map((item, index) => (
<div key={index}>
  {item.parentMenu.subMenus.length === 0 ? (
    <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
  ) : (
    <button
      className="journalMenuListItems"
      onClick={() => handleClick(item)}
    >
      <div className="d-flex align-items-center">
        <div>{item.parentMenu.name}</div>
        <div style={{ marginLeft: 5, marginTop: 4 }}>
          {openMenus[item.parentMenu.name] ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} />
          )}
        </div>
      </div>
    </button>
  )}
</div>
))}
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
isOpen && (
  <div key={menuName} className="dropDownStyle row">
    {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
      (subMenuItem, subIndex) => (
        <Link key={subIndex} to={subMenuItem.url} style={{ padding: '10px 40px' }} className="col-3">
          {subMenuItem.name}
        </Link>
      )
    )}
  </div>
)
))}
  
</div>
</div>
{/* Big Screen End */}

{/* Small Screen Start */}
<div className='showJump' style={{
background: ' rgba(31,83,140,1)',
padding: 8,
position: 'fixed',top:'4rem',left:0}}>
<Button  variant="primary" style={{width:'auto'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow2(true)
}}>
<FontAwesomeIcon icon={faBars}/>
</Button>
</div>
<Modal style={{position: 'absolute',
top: 0,
left: 0}} show={show2} 
onHide={() => setShow2(false)}>
<Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>
<div className="d-flex" >
<div>

 {headerData != null && headerData.map((item, index) => (
<div key={index}>
  {item.parentMenu.subMenus.length === 0 ? (
    <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
  ) : (
    <button
      className="journalMenuListItems"
      onClick={() => handleClick(item)}
    >
      <div className="d-flex align-items-center">
        <div>{item.parentMenu.name}</div>
        <div style={{ marginLeft: 5, marginTop: 4 }}>
          {openMenus[item.parentMenu.name] ? (
            <FontAwesomeIcon icon={faAngleLeft} />
          ) : (
            <FontAwesomeIcon icon={faAngleRight} />
          )}
        </div>
      </div>
    </button>
  )}
</div>
))}

</div>

<div >
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
isOpen && (
  <div key={menuName} className="smallDropDownStyle">
    {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
      (subMenuItem, subIndex) => (
        <div style={{padding: '10px 40px'}}>
        <Link key={subIndex} to={subMenuItem.url} className="col-3">
          {subMenuItem.name}
        </Link>
        </div>
      )
    )}
  </div>
)
))}

</div>
</div>
</Modal>
{/* Small Screen End */}

{/* Journal Header End */}

    <div className='showJump' style={{right: 0,
    background: ' rgba(31,83,140,1)',
    padding: 10,
    position: 'fixed',top:scrollDirection === "down" ? 5 : '4rem',right:5}}>
    <Button  variant="primary" style={{float:'right'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
    }}>
     Jump to :
      </Button>
    </div>
<Modal style={{right:0,top:scrollDirection === "down" ? 45 :108,width:'100%'}} show={show} 
onHide={() => setShow(false)}>
        <Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>

<div>
        
<div style={{overflowY:'auto'}}>

          
<div className='articleSmallMenu'>
{
  data &&
  <>
  {
 data.headings.map((item,index)=>
  
  <Link key={index} className={`active${index}`} onClick={() => scrollToSection(`${item.id}`,`${index}`)}>
   
   {item.Text}</Link>
 
 )
 }
  </>
}
</div>

   </div>
   </div>
</Modal>



    <div className='d-flex p-0 p-sm-4 mt-5' style={{flexFlow:'wrap'}}>
      
    <div className='pagesParaSide'>
      
      {/* {console.log(data.content)} */}
      {
        data &&
        <div className={` ${ scrollDirection === "down" ? "journalparaSidehide" : "journalparaSideshow"}`} dangerouslySetInnerHTML={{ __html: data.content }} />

      }
    </div>
    <div className="pagenavigation">
    <div style={{paddingLeft:18,fontWeight:'bold'}}>Jump To:</div>
         <div style={{borderBottom:'2px solid #305F8B',width:50,marginLeft:27,marginBottom:10,marginTop:5,borderRadius:13}}></div>
            <div style={{position: 'sticky',padding:20,
            top:150,paddingTop:0,overflow: 'auto',
            height: '100%'}}>
             
<div >

          
         <div className='articleMenu' style={{border:"none"}}>

       {
        data &&
        <>
        {
      data.headings.map((item,index)=>
        
        <Link key={index} id={`active${index}`} className='articleContents' onClick={() => scrollToSection(`${item.id}`,`${index}`)} style={{borderLeft: "4px solid #305F8B",paddingLeft:20}}>
           <div className='articleContents1' id={`active1${index}`}>
{item.Text}
</div>
          </Link>

      )
    }
    
        </>
       }
         </div>
        
            </div>
</div>
         
    </div>
    </div>
      <div>
          
      </div>



    
    </>
    
  );
}

export default JournalPages;



{/* <i className="fa fa-angle-up"></i> */}