import React, { useState, useRef, useEffect } from "react";
import "../styles/screens/editorialManagement.css";
import img1 from '../assets/screens/img1.jpg';
import img2 from '../assets/screens/img2.jpg';
import img3 from '../assets/screens/img3.jpg';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faLink,faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { Carousel,Button,Modal } from 'react-bootstrap'; 
import Logo from "../assets/components/Logo.jpg";
const JournalEditors = () => {

    const [data, setData] = useState(null);
    const [editorsdata, setEditorsData] = useState(null);
  const { journalName } = useParams();
  const [show, setShow] = useState(false);

  useEffect(() => { 
       async function fetchTrackApiData() {
         const formData = new FormData();
         formData.append("slug",journalName)
         try {
           const response = await axios.post(
             `https://app.xperttext.com/api/view-sub-category-by-slug`,
             formData,
             {
             headers: {
             "Content-Type": "multipart/form-data"
             },
             }
            
           );
   
           console.log(response.data)

             setData(response.data);
         } catch (error) {
           await console.error(error);
         }
       }
       fetchTrackApiData();
     }, [1]);

const JSON = [
  {
    img:img1,
    name:"Ren Ke Li",
    designation:"PhD",
    profession:"Professor of Surgery",
    university:"University of Toronto, Toronto, ON, Canada",
    phone:"416-581-7492",
    eMail:"renkeli@uhnres.utoronto.ca",
    articleName:"Cell, Stem cells and Regenerative Medicine",
    articleNumber:"ISSN 2472-6990"
  },
  {
    img: img2,
    name:"Clinton Jones",
    designation:"PhD",
    profession:"Professor of Infectious Diseases",
    university:"Oklahoma State University, Stillwater, Oklahoma, USA",
    phone:"405-744-1842",
    eMail:"clint.jones10@okstate.edu",
    articleName:"Journal of Emerging Diseases and Virology",
    articleNumber:"ISSN 2473-1846"
  },
  {
    img: img3,
    name:"Toru Ouchi",
    designation:"PhD",
    profession:"Professor of Cancer Genetics",
    university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
    phone:"716-845-7173",
    eMail:"Toru.Ouchi@RoswellPark.org",
    articleName:"International Journal of Cancer Research and Molecular Mechanisms",
    articleNumber:"ISSN 2381-3318"
  },
  {
    img:img1,
    name:"Ren Ke Li",
    designation:"PhD",
    profession:"Professor of Surgery",
    university:"University of Toronto, Toronto, ON, Canada",
    phone:"416-581-7492",
    eMail:"renkeli@uhnres.utoronto.ca",
    articleName:"Cell, Stem cells and Regenerative Medicine",
    articleNumber:"ISSN 2472-6990"
  },
  {
    img: img2,
    name:"Clinton Jones",
    designation:"PhD",
    profession:"Professor of Infectious Diseases",
    university:"Oklahoma State University, Stillwater, Oklahoma, USA",
    phone:"405-744-1842",
    eMail:"clint.jones10@okstate.edu",
    articleName:"Journal of Emerging Diseases and Virology",
    articleNumber:"ISSN 2473-1846"
  },
  {
    img: img3,
    name:"Toru Ouchi",
    designation:"PhD",
    profession:"Professor of Cancer Genetics",
    university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
    phone:"716-845-7173",
    eMail:"Toru.Ouchi@RoswellPark.org",
    articleName:"International Journal of Cancer Research and Molecular Mechanisms",
    articleNumber:"ISSN 2381-3318"
  },
  {
    img:img1,
    name:"Ren Ke Li",
    designation:"PhD",
    profession:"Professor of Surgery",
    university:"University of Toronto, Toronto, ON, Canada",
    phone:"416-581-7492",
    eMail:"renkeli@uhnres.utoronto.ca",
    articleName:"Cell, Stem cells and Regenerative Medicine",
    articleNumber:"ISSN 2472-6990"
  },
  {
    img: img2,
    name:"Clinton Jones",
    designation:"PhD",
    profession:"Professor of Infectious Diseases",
    university:"Oklahoma State University, Stillwater, Oklahoma, USA",
    phone:"405-744-1842",
    eMail:"clint.jones10@okstate.edu",
    articleName:"Journal of Emerging Diseases and Virology",
    articleNumber:"ISSN 2473-1846"
  },
  {
    img: img3,
    name:"Toru Ouchi",
    designation:"PhD",
    profession:"Professor of Cancer Genetics",
    university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
    phone:"716-845-7173",
    eMail:"Toru.Ouchi@RoswellPark.org",
    articleName:"International Journal of Cancer Research and Molecular Mechanisms",
    articleNumber:"ISSN 2381-3318"
  },
  {
    img:img1,
    name:"Ren Ke Li",
    designation:"PhD",
    profession:"Professor of Surgery",
    university:"University of Toronto, Toronto, ON, Canada",
    phone:"416-581-7492",
    eMail:"renkeli@uhnres.utoronto.ca",
    articleName:"Cell, Stem cells and Regenerative Medicine",
    articleNumber:"ISSN 2472-6990"
  },
  {
    img: img2,
    name:"Clinton Jones",
    designation:"PhD",
    profession:"Professor of Infectious Diseases",
    university:"Oklahoma State University, Stillwater, Oklahoma, USA",
    phone:"405-744-1842",
    eMail:"clint.jones10@okstate.edu",
    articleName:"Journal of Emerging Diseases and Virology",
    articleNumber:"ISSN 2473-1846"
  },
  {
    img: img3,
    name:"Toru Ouchi",
    designation:"PhD",
    profession:"Professor of Cancer Genetics",
    university:"Roswell Park Cancer Institute, Buffalo, New York, USA",
    phone:"716-845-7173",
    eMail:"Toru.Ouchi@RoswellPark.org",
    articleName:"International Journal of Cancer Research and Molecular Mechanisms",
    articleNumber:"ISSN 2381-3318"
  }
]

function openDetails(index){
  console.log(index,"details")
  localStorage.setItem("openDetailsId",index)
  window.open(`/editorial-management/${editorsdata[index-1].id}`, "_blank")
}

useEffect(()=>{
  async function fetchTrackApiData() {
    const formData = new FormData();
    formData.append("sub_category",journalName)
    
    try {
      const response = await axios.post(
        `https://app.xperttext.com/api/get-editorial-management`,
        formData,
        {
        headers: {
        "Content-Type": "multipart/form-data"
        },
        }
       
      );

      console.log(response.data)
      setEditorsData(response.data)
    } catch (error) {
      await console.error(error);
      // window.alert("Can't Assign Same Track Name")
    }
  }
  fetchTrackApiData();
},[1])

const [headerData, setHeaderData] = useState(null);
useEffect(() => { 
 async function fetchTrackApiData() {
   const formData = new FormData();
   formData.append("slug",journalName)
   try {
     const response = await axios.post(
       `https://app.xperttext.com/api/get-menu-by-sub-category-slug`,
       formData,
       {
       headers: {
       "Content-Type": "multipart/form-data"
       },
       }
      
     );

     console.log(response.data,"checkHeader")

     setHeaderData(response.data);
   } catch (error) {
     await console.error(error);
   }
 }
 fetchTrackApiData();
}, [1]);

const [openMenus, setOpenMenus] = useState({}); // Object to track open menus

const handleClick = (menuItem) => {
setOpenMenus({ ...openMenus, [menuItem.parentMenu.name]: !openMenus[menuItem.parentMenu.name] });
};
  return (
    <>
    <Helmet>   
        <title>
        Editorail Management
        </title>       
      </Helmet>
{/* Journal Header Start */}

{/* Big Screen Start */}
<div className="d-none d-lg-flex justify-content-between align-items-center" style={{background:'#F7F7F7',position:'fixed',top:'4rem',width:'100%',zIndex:2,paddingLeft:8,borderBottom:'1px solid white'}}>

<div className="journalHeading">
    {data && data.name &&
    <>
      {data.name}
    </>
    }
</div>
<div className="d-flex align-items-center">
{headerData != null && headerData.map((item, index) => (
<div key={index}>
  {item.parentMenu.subMenus.length === 0 ? (
    <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
  ) : (
    <button
      className="journalMenuListItems"
      onClick={() => handleClick(item)}
    >
      <div className="d-flex align-items-center">
        <div>{item.parentMenu.name}</div>
        <div style={{ marginLeft: 5, marginTop: 4 }}>
          {openMenus[item.parentMenu.name] ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} />
          )}
        </div>
      </div>
    </button>
  )}
</div>
))}
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
isOpen && (
  <div key={menuName} className="dropDownStyle row">
    {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
      (subMenuItem, subIndex) => (
        <Link key={subIndex} to={subMenuItem.url} style={{ padding: '10px 40px' }} className="col-3">
          {subMenuItem.name}
        </Link>
      )
    )}
  </div>
)
))}
  
</div>
</div>
{/* Big Screen End */}

{/* Small Screen Start */}
<div className='showJump' style={{
background: ' rgba(31,83,140,1)',
padding: 8,
position: 'fixed',top:'4rem',right:8}}>
<Button  variant="primary" style={{width:'auto'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
}}>
<FontAwesomeIcon icon={faBars}/>
</Button>
</div>
<Modal style={{position: 'absolute',
top: 0,
left: 0}} show={show} 
onHide={() => setShow(false)}>
<Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>
<div className="d-flex" >
<div>

 {headerData != null && headerData.map((item, index) => (
<div key={index}>
  {item.parentMenu.subMenus.length === 0 ? (
    <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
  ) : (
    <button
      className="journalMenuListItems"
      onClick={() => handleClick(item)}
    >
      <div className="d-flex align-items-center">
        <div>{item.parentMenu.name}</div>
        <div style={{ marginLeft: 5, marginTop: 4 }}>
          {openMenus[item.parentMenu.name] ? (
            <FontAwesomeIcon icon={faAngleLeft} />
          ) : (
            <FontAwesomeIcon icon={faAngleRight} />
          )}
        </div>
      </div>
    </button>
  )}
</div>
))}

</div>

<div >
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
isOpen && (
  <div key={menuName} className="smallDropDownStyle">
    {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
      (subMenuItem, subIndex) => (
        <div style={{padding: '10px 40px'}}>
        <Link key={subIndex} to={subMenuItem.url} className="col-3">
          {subMenuItem.name}
        </Link>
        </div>
      )
    )}
  </div>
)
))}

</div>
</div>
</Modal>
{/* Small Screen End */}

{/* Journal Header End */}
      <div style={{background:'#F7F7F7'}} className="mt-4">

    <div className="container" >

      <div className="EditorialHeading">
        Editorial Management
        <div style={{borderBottom:'3px solid rgba(31,83,140,1)',width:80,marginTop:8}}></div>
      </div>
      
      <div className="CardWrapper">
      {
        editorsdata && editorsdata.map((item,index)=>
        <>
       
            <div className="cardEditor" key={index} onClick={()=>openDetails(item.id)}>
              <div className="cardStyle d-flex ">
              {
                    item.photo &&
                <div className="postImage">
                  <img className="img-fluid" src={`https://app.xperttext.com/${item.photo}`} />
                  </div>
                   }
                <div className="postDetails">
                  {
                    item.name &&
                      <div className="postName" >{item.name}</div>
                  }
                  {
                    item.job_title &&
                      <div className="postProfession">{item.job_title}</div>
                  }
                  {
                    item.university &&
                      <div className="postUni">{item.university}</div>
                  }
                </div>
              </div>

              <div className="cardStyleDown">

              <div className="postArticleName">
                {
                  item.category &&
                      <i>
                         {item.category}
                      </i>  
                }
                    </div>
                    {
                      item.issn &&
                      <div className="postArticleName">{"("} {item.issn} {")"}</div>
                    }                
            </div>
              </div>
       
        </>
        )
      }
       </div>
    </div>
      </div>
    </>
  );
};

export default JournalEditors;
