import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import '../styles/screens/Journals.css';
import { Helmet } from 'react-helmet';
import journalImg1 from '../assets/screens/journalImg1.jpg';
import journalImg2 from '../assets/screens/journalImg2.jpg';
import journalImg3 from '../assets/screens/journalImg3.jpg';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import barImg from "../assets/screens/bar-chart.png"
import bookImg from "../assets/screens/book.png";
function Journals() {
  const journalsData=[
    {
      'journalName':'Acta Neurologica Scandinavica',
      'journalImg':journalImg1,
      'acceptanceRate':12,
      'submissionToFinalDecision':126,
      'acceptanceToPublication':19,
      'citeScore':5.6,
      'journalCitationIndicator':0.830,
      'impactFactor':3.5,
      'journalRate':900
    },
    {
      'journalName':'Acta Neurologica Scandinavica',
      'journalImg':journalImg2,
      'acceptanceRate':12,
      'submissionToFinalDecision':126,
      'acceptanceToPublication':19,
      'citeScore':5.6,
      'journalCitationIndicator':0.830,
      'impactFactor':3.5,
      'journalRate':500
    },
    {
      'journalName':'Acta Neurologica Scandinavica',
      'journalImg':journalImg3,
      'acceptanceRate':12,
      'submissionToFinalDecision':126,
      'acceptanceToPublication':19,
      'citeScore':5.6,
      'journalCitationIndicator':0.830,
      'impactFactor':3.5,
      'journalRate':1200
    },
    {
      'journalName':'Acta Neurologica Scandinavica',
      'journalImg':journalImg1,
      'acceptanceRate':12,
      'submissionToFinalDecision':126,
      'acceptanceToPublication':19,
      'citeScore':5.6,
      'journalCitationIndicator':0.830,
      'impactFactor':3.5,
      'journalRate':1500
    }
  ]
  const [data, setData] = useState(null);
  
  useEffect(() => {
     
    async function fetchTrackApiData() {
          
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-sub-category-list`,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        await console.log(response.data);
        setData(response.data)
        setFilteredData(response.data)
        setData1(response.data)
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);
  const categories = [...new Set(data?.map(item => item.category))]
  
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const filterByCategory = (category) => {
    console.log(data1)
    if (!category) {
      setFilteredData(data1); // Reset to original data on clear filter
    } else {
      const filtered = data1.filter((item) => item.category === category);
      setFilteredData(filtered);
    }
    setActiveCategory(category); // Update active category
  };
  const renderButtons = () => (
    <div style={{marginBottom:30}} className="homeJournalsMenu d-flex justify-content-center">
      <div key="all" className={activeCategory === null ? 'active mr-3' : 'mr-3'} onClick={() => filterByCategory(null)}>All</div>
      {categories.map((category) => (
        <div
          key={category}
          className={activeCategory === category ? 'active mr-3' : 'mr-3'}
          onClick={() => filterByCategory(category)}
        >
          {category}
        </div>
      ))}
    </div>
  );
 if(data === null){
  return(
    <div style={{height:2000,textAlign:'center',paddingTop:50}}>
            Loading....
    </div>
  )
 }
  return (
    <>
    <Helmet>   
        <title>
          Journals
        </title>       
    </Helmet>

      <div className='journalscontainer'>
      {renderButtons()}
      <div className='journalsCardWrapper'>
          {
            filteredData?.map((item,index)=>(
              <Link to={`/journals/${item?.slug}`} key={index} style={{textDecoration:'none',color:'black'}}  className='journalscardEditor'>
                {/* <img src={item.journalImg} /> */}
                  <div className='journalsName'>
                    {item.name}
                  </div>
                  <div style={{backgroundImage:`url(https://app.xperttext.com/${item.image})`,backgroundSize:'cover',height: 120,
   
    backgroundRepeat:'no-repeat',
    backgroundPosition: 'center center'}}>

                  </div>
                  {/* <img style={{height:50}} src={`https://app.xperttext.com/${item.image}`}/> */}
                  <div className='px-4 py-2 d-flex align-items-center'>
                    <div className='mr-2'>
                    <span className='mr-2'>
                        <img src={bookImg} style={{width:40}} />
                      </span>
                     <span style={{fontWeight:'bold'}}>ISSN</span>  
                    </div>
                    <div className='mb-1' style={{fontWeight:'bold'}}>
                    {item.issn_number ? 
                      <>
                      {item.issn_number}
                      </>:
                      <b>
                      -
                      </b>
                      }
                    </div>
                  </div>
                 
                  <div className='px-4 py-2 d-flex align-items-end'>
                    {/* <FontAwesomeIcon icon={faChartSimple}className='mr-3' style={{fontSize:28}} /> */}
                  <div className='mr-2'><img  src={barImg} style={{width:40}}/></div>  
                   <div style={{fontWeight:'bold'}}>Journal Matrics</div> 
                  </div>
                  {
                    (item.acceptance_rate === null && item.submission_to_final_decision === null && item.acceptance_to_publication === null && item.cite_score === null && item.journal_citation_indicator === null && item.impact_factor === null) ? 
                    <>
                    <div style={{height:filteredData.length === 1 ? 157 : '100%',textAlign:'center',marginBottom:40}} className='d-flex justify-content-center align-items-center px-2'>
                     <div>
                     <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#00A4B7',fontSize:32}} />
                     <div className='mt-2'>
                     Metrics will be available once more articles are published.
                     </div>
                     
                      </div> 
                    </div>
                    </>
                    :
                  <table className="py-3" style={{width: '-webkit-fill-available',height: '100%',marginBottom: 40}}>
                  
                      <tr className='journalsSingCit'>
                        <td className='journalsSingCitText'>
                          Acceptance Rate
                        </td>
                        <td className='journalsSingCitNumber'>
                         
                          {item.acceptance_rate ? 
                          <>
                          {item.acceptance_rate}%
                          </>:
                          <b>
                          -
                          </b>
                          }
                        </td>
                        
                      </tr>
                    
                   
                      <tr className=' journalsSingCit'>
                        <td className='journalsSingCitText'>
                          Submission To Final Decision
                        </td>
                        <td className='journalsSingCitNumber'>
                          {
                            item.submission_to_final_decision ?
                            <>
                            {item.submission_to_final_decision} days
                            </>
                            :
                            <b>
                              -
                            </b>
                          }
                          
                        </td>
                        
                      </tr>

                      <tr className=' journalsSingCit'>
                         <td className='journalsSingCitText'>
                          Acceptance To Publication
                        </td>
                        <td className='journalsSingCitNumber'>
                          {
                            item.acceptance_to_publication ?
                            <>
                              {item.acceptance_to_publication} days
                            </>
                            :
                            <b>
                              -
                            </b>
                          }
                        </td>
                       
                      </tr>
                    
                   
                      <tr className=' journalsSingCit'>
                         <td className='journalsSingCitText'>
                          Cite Score
                        </td>
                        <td className='journalsSingCitNumber'>
                          {item.cite_score ?
                          <>
                          {item.cite_score}
                          </>
                          :
                          <b>
                            -
                          </b>
                          }
                        </td>
                       
                      </tr>
                    
                  
                      <tr className=' journalsSingCit'>
                        <td className='journalsSingCitText'>
                          Journal Citation Indicator
                        </td>
                        <td className='journalsSingCitNumber'>
                          {item.journal_citation_indicator ?
                          <>
                          {item.journal_citation_indicator}
                          </>
                        :
                        <b>
                          -
                        </b>  
                        }
                        </td>
                        
                      </tr>
                    
                      <tr className=' journalsSingCit'>
                         <td className='journalsSingCitText'>
                          Impact Factor
                        </td>
                        <td className='journalsSingCitNumber'>
                          {item.impact_factor ?
                          <>
                          {item.impact_factor}
                          </>
                          :
                          <b>
                            -
                          </b>
                          }
                        </td>
                       
                      </tr>
                    
                  </table>
                  }
                  

                  <div className='journalsRateCont d-flex justify-content-between align-items-center'>
                  <div className='journalsRate'>
                    APC
                  </div>
                    <div className='journalsRate'>
                     
                      ${item.price}
                    </div>
                  </div>
              </Link>
            ))
          }
      </div>
      </div>
    </>
    
  );
}

export default Journals;
