import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import '../styles/screens/Home.css';
import introImg from '../assets/screens/homeImg1.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faChartSimple, faCheckCircle, faCheckSquare, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import aboutCompanyImg from '../assets/screens/homeAboutCompanyImg.jpg';
import CircularProgressbar, { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import corsalImg1 from '../assets/screens/blogImg1.jpg';
import corsalImg2 from '../assets/screens/blogImg2.jpg';
import corsalImg3 from '../assets/screens/blogImg4.jpg';
import corsalImg4 from '../assets/screens/blogImg5.jpg';
import homeGridImg2 from '../assets/screens/homeGridImg2.jpg';
import homeGridImg3 from '../assets/screens/homeGridImg3.jpg';
import homeGridImg4 from '../assets/screens/homeGridImg4.jpg';
import journalImg1 from '../assets/screens/journalImg1.jpg';
import journalImg2 from '../assets/screens/journalImg2.jpg';
import journalImg3 from '../assets/screens/journalImg3.jpg';
import axios from "axios";
const Home = ({dispatch}) => {
  const [percentage, setPercentage] = useState(0); // Initial progress at 0%
 
  useEffect(() => {
    // Simulate data fetching or other logic to get the actual percentage
    setTimeout(() => {
      const actualPercentage = 90; // Replace with your actual percentage logic
      setPercentage(actualPercentage);
    }, 1000); // Simulate delay for demonstration, adjust as needed
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);
  const [items, setItems] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

 
 
  const images=[
    {url:corsalImg1},
    {url:corsalImg1},
    {url:corsalImg1},
    {url:corsalImg1},
    {url:corsalImg2},
    {url:corsalImg2},
    {url:corsalImg2},
    {url:corsalImg2},
    {url:corsalImg3},
    {url:corsalImg3},
    {url:corsalImg3},
    {url:corsalImg3},
    {url:corsalImg4},
    {url:corsalImg4},
    {url:corsalImg4},
    {url:corsalImg4},
    {url:corsalImg2},
    {url:corsalImg2},
    {url:corsalImg4},
    {url:corsalImg4}, 
    {url:corsalImg2},
    {url:corsalImg2},
    {url:corsalImg3},
    {url:corsalImg3},
    {url:corsalImg1},
    {url:corsalImg1},
    {url:corsalImg3},
    {url:corsalImg3},
    {url:corsalImg1},
    {url:corsalImg1},
    {url:corsalImg4},
    {url:corsalImg4},  
  ]
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideContainerRef = useRef(null); // Ref to hold the slide container element
  const [disableNext,setDisableNext] = useState(false)
  const [disablePrev,setDisablePrev] = useState(true)
  useEffect(() => {
    const container = slideContainerRef.current;
    if (container) {
      const handleScroll = () => {
        console.log(container.scrollLeft,"checkkkk")
        if (container.scrollLeft === container.scrollWidth - container.clientWidth) {
          setDisableNext(true)
        }
        else if(container.scrollLeft === 0){
          setDisablePrev(true)
        }
        else{
          setDisablePrev(false)
          setDisableNext(false)
        }
      };
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleNext = () => {
    setDisablePrev(false)
    const containerWidth = slideContainerRef.current.clientWidth; // Get container width
    const maxScroll = slideContainerRef.current.scrollWidth - containerWidth; // Max scroll position
  
    // Calculate target scroll position based on current slide and container width
    const targetScroll = Math.min(currentSlide + 1) * containerWidth;
  
    // Ensure target scroll doesn't exceed max scroll
    slideContainerRef.current.scrollTo({ left: Math.min(targetScroll, maxScroll) });
  
    // Update currentSlide for state management (optional for animation)
    setCurrentSlide(prevSlide => Math.min(prevSlide + 1, images.length - 1));
  };
  
  const handlePrev = () => {
    setDisableNext(false)
    const containerWidth = slideContainerRef.current.clientWidth; // Get container width
  
    // Calculate target scroll position based on current slide and container width
    const targetScroll = Math.max(currentSlide - 1) * containerWidth;
  
    // Ensure target scroll doesn't go below 0
    slideContainerRef.current.scrollTo({ left: Math.max(targetScroll, 0) });
  
    // Update currentSlide for state management (optional for animation)
    setCurrentSlide(prevSlide => Math.max(prevSlide - 1, 0));
  };
  
  useEffect(() => {
   
    async function fetchTrackApiData() {
      const formData = new FormData();
      
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-blogs`,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );

        await console.log(response.data);
        setItems(response.data)
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);

  function formatISODate(dateString) {
    const date = new Date(dateString); // Create a Date object from the ISO string
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options); // Format according to options
  }

  const [journalsdata, setJournalsData] = useState(null);
  
  useEffect(() => {
     
    async function fetchTrackApiData() {
          
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-featured-sub-categories`,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        await console.log(response.data);
        setJournalsData(response.data)
        setData1(response.data)
        setFilteredData(response.data)
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);
  const [data, setData] = useState(null);
  
  useEffect(() => {
     
    async function fetchTrackApiData() {
      const formData = new FormData();
      formData.append("lang","en")
          
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-home-page`,
          formData,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        await console.log(response.data);
        setData(response.data)
        
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);

  const questions=data ? [
    { question: `${data.section_three_faq_one_q}`, 
    answer: `${data.section_three_faq_one_a}` },
    { question: `${data.section_three_faq_two_q}`, 
    answer: `${data.section_three_faq_two_a}` },
    { question: `${data.section_three_faq_three_q}`, 
    answer: `${data.section_three_faq_three_a}`}
  ] : [];
  const categories = [...new Set(journalsdata?.map(item => item.category))]
  
  const [data1, setData1] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const filterByCategory = (category) => {
    console.log(data1)
    if (!category) {
      setFilteredData(data1); // Reset to original data on clear filter
    } else {
      const filtered = data1.filter((item) => item.category === category);
      setFilteredData(filtered);
    }
    setActiveCategory(category); // Update active category
  };
  const renderButtons = () => (
    <div style={{marginBottom:30}} className="homeJournalsMenu d-flex justify-content-center">
      <div key="all" className={activeCategory === null ? 'active mr-3' : 'mr-3'} onClick={() => filterByCategory(null)}>All</div>
      {categories.map((category) => (
        <div
          key={category}
          className={activeCategory === category ? 'active mr-3' : 'mr-3'}
          onClick={() => filterByCategory(category)}
        >
          {category}
        </div>
      ))}
    </div>
  );
 if(data === null){
  return(
    <div style={{height:2000,textAlign:'center',paddingTop:50}}>
            Loading....
    </div>
  )
 }

return (
    <>
    <Helmet>   
        <title>
       Home
        </title>       
      </Helmet>
      
      <div className="homeIntroCont">
        <div className="introImgCont">
         <div className="image">
         {/* introImg */}
               <img  src={introImg} />
         </div>
        </div>
        <div className="introContentCont">
        <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
          <div className="introSubTitle">
           {data.section_one_title}
          </div>

          <h2 className="introTitle">
            {data.section_one_sub_title}
          </h2>

          <h3 className="introTitleBelowText">
            {data.section_one_services_title}
          </h3>

          <div className="d-flex justify-content-between">
            <div>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon style={{color:'#00A4B7'}} icon={faCheckCircle} />
                <div className="ml-2 introFeaturesText">{data.section_one_service_one}</div>
            </div>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon style={{color:'#00A4B7'}} icon={faCheckCircle} />
                <div className="ml-2 introFeaturesText">{data.section_one_service_three}</div>
            </div>
            </div>
            <div>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon style={{color:'#00A4B7'}} icon={faCheckCircle} />
                <div className="ml-2 introFeaturesText">{data.section_one_service_two}</div>
            </div>
            <div className="d-flex align-items-center">
                <FontAwesomeIcon style={{color:'#00A4B7'}} icon={faCheckCircle} />
                <div className="ml-2 introFeaturesText">{data.section_one_service_four}</div>
            </div>
            </div>
            
           
          </div>


          <div className="introDesc">
          {data.section_one_description}
           </div>

        </div>
        <div>

        </div>
      </div>
      <div className='homejournalscontainer'>
  <div className="d-flex justify-content-center">
      <div>
    <div className="d-flex justify-content-center mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
          <div className="introSubTitle" style={{textAlign:'center'}}>
          RECENTLY COMPLETED
          </div>

          <h2 className="introTitle" style={{textAlign:'center'}}>
          Explore Our Journals By Discipline
          </h2>
          
      </div>
    </div>
    {renderButtons()}
      <div className='homejournalsCardWrapper'>
      {
            filteredData?.map((item,index)=>(
              <Link to={`/journals/${item?.slug}`} key={index} style={{textDecoration:'none',color:'black'}}  className='journalscardEditor'>
                {/* <img src={item.journalImg} /> */}
                  <div className='homejournalsName'>
                    {item.name}
                  </div>
                  <img style={{height:50}} src={`https://app.xperttext.com/${item.image}`}/>
                  <div className='px-4 py-2'>
                    <FontAwesomeIcon icon={faChartSimple}className='mr-3' style={{fontSize:28}} />
                    Journal matrics
                  </div>
                  {
                    (item.acceptance_rate === null && item.submission_to_final_decision === null && item.acceptance_to_publication === null && item.cite_score === null && item.journal_citation_indicator === null && item.impact_factor === null) ? 
                    <>
                    <div style={{height:filteredData.length === 1 ? 157 : '100%',textAlign:'center',marginBottom:40}} className='d-flex justify-content-center align-items-center px-2'>
                     <div>
                     <FontAwesomeIcon icon={faExclamationCircle} style={{color:'#00A4B7',fontSize:32}} />
                     <div className='mt-2'>
                     Metrics will be available once more articles are published.
                     </div>
                     
                      </div> 
                    </div>
                    </>
                    :
                  <table className="py-3" style={{width: '-webkit-fill-available',height: '100%',marginBottom: 40}}>
                  
                      <tr className='homejournalsSingCit'>
                        <td className='homejournalsSingCitText'>
                          Acceptance Rate
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {item.acceptance_rate ? 
                          <>
                          {item.acceptance_rate}%
                          </>:
                          <b>
                          -
                          </b>
                          }
                        </td>
                        
                      </tr>
                    
                   
                      <tr className=' journalsSingCit'>
                        <td className='homejournalsSingCitText'>
                          Submission To Final Decision
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {
                            item.submission_to_final_decision ?
                            <>
                            {item.submission_to_final_decision} days
                            </>
                            :
                            <b>
                              -
                            </b>
                          }
                          
                        </td>
                        
                      </tr>

                      <tr className=' journalsSingCit'>
                         <td className='homejournalsSingCitText'>
                          Acceptance To Publication
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {
                            item.acceptance_to_publication ?
                            <>
                              {item.acceptance_to_publication} days
                            </>
                            :
                            <b>
                              -
                            </b>
                          }
                        </td>
                       
                      </tr>
                    
                   
                      <tr className=' journalsSingCit'>
                         <td className='homejournalsSingCitText'>
                          Cite Score
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {item.cite_score ?
                          <>
                          {item.cite_score}
                          </>
                          :
                          <b>
                            -
                          </b>
                          }
                        </td>
                       
                      </tr>
                    
                  
                      <tr className=' journalsSingCit'>
                        <td className='homejournalsSingCitText'>
                          Journal Citation Indicator
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {item.journal_citation_indicator ?
                          <>
                          {item.journal_citation_indicator}
                          </>
                        :
                        <b>
                          -
                        </b>  
                        }
                        </td>
                        
                      </tr>
                    
                      <tr className=' journalsSingCit'>
                         <td className='homejournalsSingCitText'>
                          Impact Factor
                        </td>
                        <td className='homejournalsSingCitNumber'>
                          {item.impact_factor ?
                          <>
                          {item.impact_factor}
                          </>
                          :
                          <b>
                            -
                          </b>
                          }
                        </td>
                       
                      </tr>
                    
                  </table>
                  }
                  

                  <div className='homejournalsRateCont'>
                    <div className='homejournalsRate'>
                      ${item.price}
                    </div>
                  </div>
              </Link>
            ))
          }
      </div>
      </div>

      <div className="bgCont">
          <div className="bgContoverlay">
            <div className="bgTextCont">
              <div>
            <h2 className="bgText">
                {data.section_two_title}
             </h2>
             <div className="bgButtonCont">
              <Link to={data.section_two_button_link} className="bgButton">
                  {data.section_two_button_title}
              </Link>
                
             </div>
              </div>
            </div>
          </div>
      </div>

      <div className="homeAboutCompanyCont">
        <div className="homeAboutCompanyUpCont">
            <div className="homeAboutCompanyUpLeftCont">
            <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
              <div className="introSubTitle">
                 {data.section_three_title}
              </div>
              <div className="introTitle">
                  {data.section_three_sub_title}
              </div>
            </div>
            <div  className="homeAboutCompanyUpRightCont">
              {data.section_three_description}
            </div>
        </div>
        <div className="homeAboutCompanyDownCont">
          <div className="homeAboutCompanyImg" style={{backgroundImage:`url(${aboutCompanyImg})`}}>
          </div>
          <div className="homeAboutCompanyMiddleCont">
             <div>
                <div style={{width:152,height:152}}>
                <CircularProgressbarWithChildren  styles={{
                path: { strokeWidth: '3px' },
                trail: { strokeWidth: '3px' },
                 }} value="90">
                  <div style={{ fontSize: '22px',fontWeight:'bold',color:'black' }}>
                    {data.section_three_cost}
                  </div>
                </CircularProgressbarWithChildren>
                    </div>
                <div className="progressBarTextUp">
                  {data.section_three_cost_title}
                </div>
             </div>
             <div>
             <div className="progressBarDownCont" style={{width:152,height:152}}>
                <CircularProgressbarWithChildren  styles={{
                path: { strokeWidth: '3px' },
                trail: { strokeWidth: '3px' },
            }} value="94">
                  <div style={{ fontSize: '22px',fontWeight:'bold',color:'black' }}>
                    {data.section_three_work}
                  </div>
                </CircularProgressbarWithChildren>
                    </div>
            <div className="progressBarText">
              {data.section_three_work_title}
            </div>
             </div>
          </div>
          <div className="homeFAQAccordian">
          {questions.map((question, index) => (
        <div
          key={index}
          className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
        >
          <button className="accordion-question" onClick={() => toggleFAQ(index)}>
            <div style={{fontWeight:700,fontSize:18}}>
            {question.question}
            </div>
            
            <div className="icon">
              {activeIndex === index ? (
                <FontAwesomeIcon icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} />
              )}
            </div>
          </button>
          <div className={`accordion-answer ${activeIndex === index ? 'active' : 'hidden'}`}>
            <p>{question.answer}</p>
          </div>
        </div>
      ))}
          </div>
        </div>
      </div>
      
  <div className="slider">
    <div className="d-flex justify-content-center">
      <div>
    <div className="d-flex justify-content-center mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
          <div className="introSubTitle" style={{textAlign:'center'}}>
            {data.section_four_title}
          </div>

          <h2 className="introTitle" style={{textAlign:'center'}}>
            {data.section_four_sub_title}
          </h2>
      </div>
    </div>
      <div className="d-flex justify-content-between">
  
  <div>
  
  </div>
</div>

<div style={{position:'relative'}}>

<div style={{position:'absolute',left:-13,top:'25%',zIndex:2}}>
<button  disabled={disablePrev} className="sliderButtons" onClick={handlePrev}>
    <FontAwesomeIcon style={{color:'white',fontSize:22}} icon={faAngleLeft} />
  </button>
</div>
    <div
      className="slide-container"
      style={{ height: 383, overflowX: 'auto', display: 'flex',scrollbarWidth:'none',justifyContent: items?.length === 1 && 'center' }}
      ref={slideContainerRef} // Assign ref to the container
    >
       
      {
      items?.map((data,index)=>(
      <Link to={`/blog/${data?.slug}`} key={index} style={{marginRight:20,position:'relative',textDecoration:'none',color:'black'}}>
        <img style={{borderRadius:8,width:370}} src={`https://app.xperttext.com/${data?.image1}`}/>
        <div className="blogTag">
              {data?.category?.name}
        </div>
        <div className="homeSliderContent">
            {data?.name}
        </div>
        <div className="homeSliderDate mt-1">
        {formatISODate(data?.created_at)}
        </div>
      </Link>
    ))
  }
   
    </div>
    <div style={{position:'absolute',right:-17,top:'25%',zIndex:2}}>
    <button disabled={disableNext} className="sliderButtons" onClick={handleNext}>
  <FontAwesomeIcon style={{color:'white',fontSize:22}} icon={faAngleRight} />
  </button>
    </div>
</div>


  </div>

  <div className="homeBlock5">
      <div className="homeBlock5Left">
          <div className="d-flex">
            <div className="homeGridTitle d-flex justify-content-center align-items-center" >
              <div>
                {data.section_five_image_text}
              </div>
            </div>
            <div style={{width:'50%',border:'2px solid white'}}>
            {/* section_five_image1 */}
            {/* homeGridImg2 */}
                <img style={{width:'100%'}} src={`https://app.xperttext.com/${data.section_five_image1}`} />
            </div>
          </div>
          <div className="d-flex">
            <div style={{width:'50%',border:'2px solid white'}}>
            {/* homeGridImg3 */}
                <img style={{width:'100%'}} src={`https://app.xperttext.com/${data.section_five_image2}`} />
            </div>
            <div style={{width:'50%',border:'2px solid white'}}>
            {/* homeGridImg4 */}
                <img style={{width:'100%'}} src={`https://app.xperttext.com/${data.section_five_image3}`} />
            </div>
          </div>
      </div>
      <div className="homeBlock5Right">
      <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
          <div className="introSubTitle">
            {data.section_five_title}
          </div>

          <h2 className="introTitle">
            {data.section_five_sub_title}
          </h2>

          <div className="introDesc">
            {data.section_five_description}
          </div>

          <div style={{marginTop:20}} className="d-flex">
          <div className="mr-3">
            <FontAwesomeIcon style={{color:'#00A4B7',width:50,height:50}} icon={faCheckSquare} />
          </div>
          <div>
          <h4>
              {data.section_five_point1_title}
          </h4>
          <div className="introDesc" style={{marginTop:0}}>
            {data.section_five_point1_description}
          </div>
          </div>
         </div>
         <div style={{marginTop:20}} className="d-flex">
          <div className="mr-3">
            <FontAwesomeIcon style={{color:'#00A4B7',width:50,height:50}} icon={faCheckSquare} />
          </div>
          <div>
          <h4>
              {data.section_five_point2_title}
          </h4>
          <div className="introDesc" style={{marginTop:0}}>
            {data.section_five_point2_description}
          </div>
          </div>
         </div>
      </div>
  </div>

  
    </>
  );
};

export default connect() (Home);
