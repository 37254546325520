import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import "../styles/screens/JournalContents.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faLink,faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { Carousel,Button,Modal } from 'react-bootstrap'; 
import Logo from "../assets/components/Logo.jpg";
const JournalContents = ({dispatch}) => {
  const [data, setData] = useState(null);
  const { journalName } = useParams();
  const [show, setShow] = useState(false);

  useEffect(() => { 
       async function fetchTrackApiData() {
         const formData = new FormData();
         formData.append("slug",journalName)
         try {
           const response = await axios.post(
             `https://app.xperttext.com/api/view-sub-category-by-slug`,
             formData,
             {
             headers: {
             "Content-Type": "multipart/form-data"
             },
             }
            
           );
   
           console.log(response.data)

             setData(response.data);
         } catch (error) {
           await console.error(error);
         }
       }
       fetchTrackApiData();
     }, [1]);

     const [headerData, setHeaderData] = useState(null);
     useEffect(() => { 
      async function fetchTrackApiData() {
        const formData = new FormData();
        formData.append("slug",journalName)
        try {
          const response = await axios.post(
            `https://app.xperttext.com/api/get-menu-by-sub-category-slug`,
            formData,
            {
            headers: {
            "Content-Type": "multipart/form-data"
            },
            }
           
          );
  
          console.log(response.data,"checkHeader")

          setHeaderData(response.data);
        } catch (error) {
          await console.error(error);
        }
      }
      fetchTrackApiData();
    }, [1]);

    const [openMenus, setOpenMenus] = useState({}); // Object to track open menus

  const handleClick = (menuItem) => {
    setOpenMenus({ ...openMenus, [menuItem.parentMenu.name]: !openMenus[menuItem.parentMenu.name] });
  };

    let cards=[
        {
            journalName:'Medicinal Plant Biology',
            journalDes:'Published by Maximum Academic Press in China Accepts manuscripts in English',
            journalSubject:'Science: Biology (General), Science: Botany: Plant ecology',
            journalDate:'Last updated on 3 Mar 2024'
        },
        {
            journalName:'Medicinal Plant Biology',
            journalDes:'Published by Maximum Academic Press in China Accepts manuscripts in English',
            journalSubject:'Science: Biology (General), Science: Botany: Plant ecology',
            journalDate:'Last updated on 3 Mar 2024'
        },
        {
            journalName:'Medicinal Plant Biology',
            journalDes:'Published by Maximum Academic Press in China Accepts manuscripts in English',
            journalSubject:'Science: Biology (General), Science: Botany: Plant ecology',
            journalDate:'Last updated on 3 Mar 2024'
        },
        {
            journalName:'Medicinal Plant Biology',
            journalDes:'Published by Maximum Academic Press in China Accepts manuscripts in English',
            journalSubject:'Science: Biology (General), Science: Botany: Plant ecology',
            journalDate:'Last updated on 3 Mar 2024'
        }
    ]
 
  return (
    <>
    <Helmet>   
        <title>
          Contents
        </title>       
      </Helmet>
{/* Journal Header Start */}

{/* Big Screen Start */}
      <div className="d-none d-lg-flex justify-content-between align-items-center" style={{background:'#F7F7F7',position:'fixed',top:'4rem',width:'100%',zIndex:2,paddingLeft:8}}>

        <div className="journalHeading">
            {data && data.name &&
            <>
              {data.name}
            </>
            }
        </div>
        <div className="d-flex align-items-center">
        {headerData != null && headerData.map((item, index) => (
        <div key={index}>
          {item.parentMenu.subMenus.length === 0 ? (
            <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
          ) : (
            <button
              className="journalMenuListItems"
              onClick={() => handleClick(item)}
            >
              <div className="d-flex align-items-center">
                <div>{item.parentMenu.name}</div>
                <div style={{ marginLeft: 5, marginTop: 4 }}>
                  {openMenus[item.parentMenu.name] ? (
                    <FontAwesomeIcon icon={faAngleUp} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleDown} />
                  )}
                </div>
              </div>
            </button>
          )}
        </div>
      ))}
      {Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
        isOpen && (
          <div key={menuName} className="dropDownStyle row">
            {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
              (subMenuItem, subIndex) => (
                <Link key={subIndex} to={subMenuItem.url} style={{ padding: '10px 40px' }} className="col-3">
                  {subMenuItem.name}
                </Link>
              )
            )}
          </div>
        )
      ))}
          
        </div>
      </div>
{/* Big Screen End */}

{/* Small Screen Start */}
<div className='showJump' style={{
    background: ' rgba(31,83,140,1)',
    padding: 8,
    position: 'fixed',top:'4rem',right:8}}>
    <Button  variant="primary" style={{width:'auto'}} className="sticky-top showPagesMenuBars" 
onClick={() => {setShow(true)
    }}>
     <FontAwesomeIcon icon={faBars}/>
      </Button>
    </div>
<Modal style={{position: 'absolute',
  top: 0,
  left: 0}} show={show} 
onHide={() => setShow(false)}>
    <Modal.Header closeButton> <img style={{ height: "50px" }} src={Logo} /></Modal.Header>
<div className="d-flex" >
<div>

         {headerData != null && headerData.map((item, index) => (
        <div key={index}>
          {item.parentMenu.subMenus.length === 0 ? (
            <Link className="journalMenuListItems" to={item.parentMenu.url}>{item.parentMenu.name}</Link>
          ) : (
            <button
              className="journalMenuListItems"
              onClick={() => handleClick(item)}
            >
              <div className="d-flex align-items-center">
                <div>{item.parentMenu.name}</div>
                <div style={{ marginLeft: 5, marginTop: 4 }}>
                  {openMenus[item.parentMenu.name] ? (
                    <FontAwesomeIcon icon={faAngleLeft} />
                  ) : (
                    <FontAwesomeIcon icon={faAngleRight} />
                  )}
                </div>
              </div>
            </button>
          )}
        </div>
      ))}
      
</div>

<div >
{Object.entries(openMenus) != null && Object.entries(openMenus).map(([menuName, isOpen]) => (
        isOpen && (
          <div key={menuName} className="smallDropDownStyle">
            {headerData.find((dataItem) => dataItem.parentMenu.name === menuName)?.parentMenu.subMenus.map(
              (subMenuItem, subIndex) => (
                <div style={{padding: '10px 40px'}}>
                <Link key={subIndex} to={subMenuItem.url} className="col-3">
                  {subMenuItem.name}
                </Link>
                </div>
              )
            )}
          </div>
        )
      ))}

</div>
</div>
</Modal>
{/* Small Screen End */}

{/* Journal Header End */}

      <div className="py-4 mt-5">
        {
            cards.map((data,index)=>
                <div key={index} className="ContentCard mx-3 mx-md-5 my-3">
                        <div className="row">
                              <div className="ContentCardRight col-md-8 col-12">
                                 <h3 className="CardJournalName">
                                   <Link>{data.journalName}
                                       <FontAwesomeIcon icon={faLink} style={{width:15,marginLeft:8}} />
                                   </Link>
                                 </h3>
                                 <div className="CardJournalDes">
                                    {data.journalDes}
                                 </div>
                                 <h6 style={{fontWeight: 600,margin:'.75rem 0 .375rem 0'}}>
                                    Journal subjects
                                 </h6>
                                 <div className="CardJournalSubject">
                                    {data.journalSubject}
                                 </div>
                              </div>
                              <div className="ContentCardLeft col-md-4 col-12">
                                <div>
                                <div className="CardJournalDate mb-3  mb-md-5">
                                    {data.journalDate}
                                </div>
                                <div className="d-flex justify-content-between align-items-center px-2">
                                    <div className="w-50 downloadText">
                                      <span className="hideMedium">Download</span> PDF  <FontAwesomeIcon className="ml-2" icon={faDownload} />
                                    </div>
                                    <div className="w-50 downloadText">
                                    <span className="hideMedium">Download</span> Citation  <FontAwesomeIcon className="ml-2" icon={faDownload} />
                                    </div>
                                </div>
                                </div>
                              </div>
                        </div>
                </div>
            )
        }
      </div>
      
    </>
  );
};

export default connect() (JournalContents);
