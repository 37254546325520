import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import '../styles/screens/Blogs.css';
import blogImg1 from '../assets/screens/blogImg1.jpg';
import blogImg2 from '../assets/screens/blogImg2.jpg';
import blogImg3 from '../assets/screens/blogImg4.jpg';
import blogImg4 from '../assets/screens/blogImg4.jpg';
import blogImg5 from '../assets/screens/blogImg5.jpg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight,faClose, faFilter } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import axios from "axios";
const Blog = ({dispatch}) => {

const [items, setItems] = useState(null);
useEffect(() => {
   
  async function fetchTrackApiData() {
    const formData = new FormData();
    
    try {
      const response = await axios.post(
        `https://app.xperttext.com/api/get-blogs`,
        {
        headers: {
        "Content-Type": "multipart/form-data"
        },
        }
       
      );

      await console.log(response.data);
      setItems(response.data)
      
    } catch (error) {
      await console.error(error);
      // window.alert("Can't Assign Same Track Name")
    }
  }
  fetchTrackApiData();
}, [1]);

function formatISODate(dateString) {
  const date = new Date(dateString); // Create a Date object from the ISO string
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return date.toLocaleDateString('en-US', options); // Format according to options
}
const [currentPage, setCurrentPage] = useState(1);
const [selectedTags, setSelectedTags] = useState([]);
const [show, setShow] = useState(false);
  const blogsPerPage = 10;


   const handleNext = () => {
    const totalPages = Math.ceil(filteredBlogData.length / blogsPerPage);
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };


  const handlePrevious = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

 
  const startIndex = (currentPage - 1) * blogsPerPage;
 
  const uniqueTags = new Set(items?.map(item => item.category.name)); 

  const filteredBlogData = selectedTags.length === 0
    ? items
    : items.filter(item => selectedTags.some(tag => item.category.name === tag));
  
  const currentBlogs = items?.length > 10 ? filteredBlogData.slice(startIndex, startIndex + blogsPerPage) : filteredBlogData;

  const handleTagClick = (tag) => {
    const newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter(t => t !== tag) 
      : [...selectedTags, tag];
    setSelectedTags(newSelectedTags);
    setCurrentPage(1); 
  };

  const renderTagButtons = () => {
    return Array.from(uniqueTags).map(tag => (
      <button key={tag} className={`col-3 tagStyle ${selectedTags.includes(tag) ? 'tagactive' : ''}`} onClick={() => handleTagClick(tag)}>
        {tag}
      </button>
    ));
  };
  function formatISODate(dateString) {
    const date = new Date(dateString); // Create a Date object from the ISO string
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options); // Format according to options
  }



return (
    <>
    <Helmet>   
        <title>
        Blog
        </title>       
      </Helmet>
      <div className="blogContainer">
        <div className="filtersButton">
<FontAwesomeIcon icon={faFilter} onClick={() => {setShow(true)
}} style={{color:'white',fontSize:24}}/>
        </div>
        <Modal style={{position: 'absolute',
top: 0,
left: 0}} show={show} 
onHide={() => setShow(false)}>
<Modal.Header closeButton></Modal.Header>
<div>
          <div className="d-flex justify-content-between align-items-center px-4">
          <h4 style={{ fontWeight: 'bold' }}>
            Tags
          </h4>
          {
            selectedTags.length != 0 &&
              <div style={{fontSize:12,color:'red',cursor:'pointer'}} onClick={()=>{
                setSelectedTags([])
              }}>
                Clear Filter <FontAwesomeIcon style={{cursor:'pointer'}}  icon={faClose} />
              </div>
          }
          </div>
          <div className="headerBorder"></div>
        </div>
        <div className="row" style={{ rowGap: 5, columnGap: 24,width: '100%',marginLeft:'auto',marginBottom:20 }}>
          {renderTagButtons()}
        </div>
</Modal>
      <div className="blogsSide">
      {currentBlogs?.map((item, index) => (
        <div key={index} className="singleBlog">
          <Link to={`/blog/${item.slug}`} className="blogImgCont">
            <img className="blogImg" src={`https://app.xperttext.com/${item?.image1}`} alt={item.name} />
            <div className="blogTag">
              {item.category.name}
            </div>
          </Link>
          <div className="blogDescCont">
          <Link to={`/blog/${item.slug}`} style={{textDecoration:'none',color:'black'}}>
          <h3 className="blogName">
              {item.name}
            </h3>
          </Link>
            <div className="blogDesc"  dangerouslySetInnerHTML={{ __html: item.content }}
        style={{ maxHeight: 70, overflow: 'clip',marginBottom:0 }}>
             
            </div>
            
        <Link style={{fontSize:10,marginBottom:8}} to={`/blog/${item.slug}`}>Read More</Link>
      
            <div className="blogDate">
            {formatISODate(item.created_at)}
            </div>
          </div>
        </div>
      ))}

      <div className="pagination-buttons d-flex justify-content-between align-items-center" style={{ marginLeft: 25 }}>
        <div>
          {currentPage > 1 && (
            <button className="pageButtons" onClick={handlePrevious}>
              <FontAwesomeIcon className="mr-2" icon={faAngleLeft} /> Previous
            </button>
          )}
        </div>
        
        {
          Math.ceil(filteredBlogData?.length / blogsPerPage) > 1 &&
            <div style={{ color: '#777' }}>
              {currentPage}/{Math.ceil(filteredBlogData?.length / blogsPerPage)}
            </div>
        }
        <div>
          {currentPage < Math.ceil(filteredBlogData?.length / blogsPerPage) && (
            <button className="pageButtons" onClick={handleNext}>
              Next <FontAwesomeIcon className="ml-2" icon={faAngleRight} />
            </button>
          )}
        </div>
      </div>
    
      

    </div>

          <div className="tagFiltersCont">
        <div>
          <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: 'bold' }}>
            Tags
          </h4>
          {
            selectedTags.length != 0 &&
            <div style={{fontSize:12,color:'red',cursor:'pointer'}} onClick={()=>{
              setSelectedTags([])
            }}>
              Clear Filter <FontAwesomeIcon style={{cursor:'pointer'}}  icon={faClose} />
            </div>
          }
          </div>
          <div className="headerBorder"></div>
        </div>
        <div className="row" style={{ rowGap: 5, columnGap: 24,width: '100%',float: 'right' }}>
          {renderTagButtons()}
        </div>
      </div>
      </div>
    </>
  );
};

export default connect() (Blog);
