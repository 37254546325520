import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import "../styles/screens/contactUS.css";
import axios from 'axios';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const ContactUs = ({dispatch}) => {

  const [data, setData] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [openError, setopenError] = useState(false);
  const [formData, setformData] = useState({});

   useEffect(() => {
       
        async function fetchTrackApiData() {
          const formData = new FormData();
          formData.append("lang","en")
          
          try {
            const response = await axios.post(
              `https://app.xperttext.com/api/contact-page-info`,
              formData,
              {
              headers: {
              "Content-Type": "multipart/form-data"
              },
              }
             
            );
    
            console.log(response.data)

              setData(response.data);
          } catch (error) {
            await console.error(error);
            // window.alert("Can't Assign Same Track Name")
          }
        }
        fetchTrackApiData();
      }, [1]);
      const handleSubmit = async (event) => {
        event.preventDefault();
       console.log(formData['entry.596556922'] !== "" && 
       formData['entry.1064176835'] !== "" && 
       formData['entry.1671492495'] !== "")
      
       
        if(formData['entry.596556922'] !== "" && 
        formData['entry.1064176835'] !== "" && 
        formData['entry.1671492495'] !== ""){
    
          fetch("https://docs.google.com/forms/d/e/1FAIpQLScPEEJ0uxS-cf-RRQTpNSHRKUnxx_k2djTaVhplotpc53cfVQ/formResponse", {
          method: "POST",
          body: new URLSearchParams(formData),
          mode: "no-cors" // Not recommended for production
          })
          .then(() => {
            console.log("Form submission attempted (CORS disabled)");
            const form = document.getElementById("contact-form"); // Replace with your form ID
          form.querySelectorAll("input").forEach(input => input.value = "");
            setSubmitted(true)
          })
          .catch((error) => {
            console.error("Form submission error:", error);
          });
        }
        else{
        setopenError(true)
        }
        };

        function  handleChange(event){
        
          setformData({
            ...formData,
            [event.target.name]: event.target.value, });
          };
  return (
    <div  className="px-xl-5 pb-5" style={{paddingTop:0}}>
      <Helmet>   
        <title>
        Contact Us
        </title>       
      </Helmet>
     {/* <div className="aboutUs">
        <div className="textInImg">
          <div className="route"></div>
          <h1 className="heading">
            Contact Us
          </h1>
          <div className="description">
          Whether you have a question about our journals or publishing partnerships, our team is here to help.          </div>
        </div>
        </div> */}
        <div className='mapContainer'>
          {
            data && data.google_maps_link &&
            <>
                  <div className="mapCont" dangerouslySetInnerHTML={{ __html: data.google_maps_link.content }} />

            </>
          }
          {/* <iframe className='map'  height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a>
        </iframe> */}
        </div>
        <div className='contactInfo d-lg-flex justify-content-between'>
          <div className='contactInfoLeft pt-5'>
          {data &&

          <>
          {
                data.know_about_company &&

                <>
                  <h3>
                  {data.know_about_company.title}
                  </h3>
                  <div>
                  {data.know_about_company.content}
                  </div>

                </>
          }
          </>

}
          </div>
          <div className='contactUsCardCont'>
      <div>
        <div>
          {data &&
          <>
          {
            data.fellow_us &&
            <>
             {
              data.fellow_us.contents &&
              <>
              {
            data.fellow_us.contents.call_us &&
            <p>{data.fellow_us.contents.call_us.title}<p><b>{data.fellow_us.contents.call_us.content}</b></p> </p>

          }
        {
          data.fellow_us.contents.mail_us &&
            <p>{data.fellow_us.contents.mail_us.title}<p><b>{data.fellow_us.contents.mail_us.content}</b></p> </p>
        }
        {
          data.fellow_us.contents.find_us &&
          <p>{data.fellow_us.contents.find_us.title}<p><b>{data.fellow_us.contents.find_us.content}</b></p> </p>
        }

              </>
             }
            </>
          }
          
        
          </>

          }
        {/* <p>Send Email<p><b> help@hindawi.com</b></p> </p>
        <p>Visit Office<p><b>
        Adam House, Third Floor<br></br>
        1 Fitzroy Square<br></br>London W1T 5HF<br></br>United Kingdom
          </b></p> </p> */}
        
        
        </div>
        </div>
        <hr style={{color:'gray'}}/>
      
      <div className='d-flex contactUsSocialIcons'>
        {
          data &&
          <>
          {
            data.social_media &&
            <>
            {
              data.social_media.facebook && data.social_media.facebook.content && data.social_media.facebook.title &&
                <Link className='facebook' target="_blank" to={data.social_media.facebook.content}>
                </Link>
            }
            {
              data.social_media.instagram && data.social_media.instagram.content && data.social_media.instagram.title &&
                <Link className='insta' target="_blank" to={data.social_media.instagram.content}>
                </Link>
            }
            {
              data.social_media.twitter && data.social_media.twitter.content && data.social_media.twitter.title &&
                <Link className='twitter' target="_blank" to={data.social_media.twitter.content}>
                </Link>
            } 
            {
              data.social_media.linkedin && data.social_media.linkedin.content && data.social_media.linkedin.title &&
                <Link className='linkedin' target="_blank" to={data.social_media.linkedin.content}>
                </Link>
            } 
            {
              data.social_media.pinterest && data.social_media.pinterest.content && data.social_media.pinterest.title &&
                <Link className='linkedin' target="_blank" to={data.social_media.pinterest.content}>
                </Link>
            } 

            
            
            </>
          }
          </>
        }
      </div>
          </div>
        </div>
        <div className='d-flex justify-content-center' style={{marginTop:40}}>
          <div>
            CONTACT WITH US
          </div>
        </div>
        <div className=' d-flex justify-content-center'>
          <h2 style={{fontWeight:700}}>
            Drop us a Message
          </h2>
        </div>
        <form id="contact-form" onSubmit={handleSubmit} >

        <div className='msgCont d-lg-flex justify-content-between align-items-center' style={{padding:25}}>
          <div className='contactFormInputCont'>
          <input required  onChange={handleChange} name="entry.596556922" className='contactFormInput' placeholder='Your Name' type='text' />
          </div>
          <div className='contactFormInputCont'>
          <input onChange={handleChange} name="entry.551467226" className='contactFormInput' placeholder='Phone Number' type='tel' />
          </div>
        </div>
        <div className='msgCont d-lg-flex justify-content-between align-items-center' style={{padding:25}}>
          <div className='contactFormInputCont'>
          <input required onChange={handleChange} name="entry.1064176835" className='contactFormInput' placeholder='Email Address' type='email' />
          </div>
          <div className='contactFormInputCont'>
          <input onChange={handleChange} name="entry.456111556" className='contactFormInput' placeholder='Subject' type='text' />
          </div>
        </div>
        <div className='msgCont d-lg-flex justify-content-between align-items-center' style={{padding:25}}>
          <div className='contactFormInputCont1'>
          <input required onChange={handleChange} name="entry.1671492495" style={{height:100}} className='contactFormInput' placeholder='Write a Message' type='text' />
          </div>
        </div>

        <div className='msgCont d-lg-flex justify-content-between align-items-center' style={{padding:25}}>
          
          <button type="submit" className='contactFormInputButton'>
          Send a Message
          </button> 
        </div>

        </form>

    </div>
  );

};

export default connect() (ContactUs);
