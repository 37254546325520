import {React,useEffect,useState} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
// Components
 import HeaderDiv from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/scrollToTop';
// Screens
import Home from './screens/home';
import Journals from './screens/journals';
import AboutUs from './screens/aboutUs';
import PublishWithUs from './screens/publishWithUs';
import PublishingPatnership from './screens/publishingPatnership';
import Blog from './screens/blog';
import SingleBlog from './screens/singleBlog';
import ContactUs from './screens/contactUs';
import EditorialManagement from './screens/editorialManagement';
import PostDetails from './screens/postDetils';
import Pages from './screens/pages';
import PagesTest from './screens/pagesTest';
import Journal from './screens/journal';
import JournalContents from './screens/journalContents';
import JournalEditors from './screens/journalEditors';
import JournalPages from './screens/journalPages';
import Articles from './screens/articles';
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
 
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    }
  }, [scrollDirection]);

  return scrollDirection;
};
function Header() {
  const scrollDirection = useScrollDirection();
  
  return (
    <div className={`headerStyle show`}>
      {/* `headerStyle ${ scrollDirection === "down" ? "hide" : "show"}` */}
     <HeaderDiv/>
    </div>
  );
};


function App() {
 
  return (      
<div className="App">
<ScrollToTop/>
<HeaderDiv/>
<Routes>
<Route exact path='/' element={<Home />} />
<Route exact path='/journals' element={<Journals />} />
<Route exact path='/journals/:journalName' element={<Journal />} />
<Route exact path='/journals/:journalName/contents' element={<JournalContents />} />
<Route exact path='/journals/:journalName/editors' element={<JournalEditors />} />
<Route exact path='/journals/:journalName/pages/:pageName' element={<JournalPages />} />
<Route exact path='/publish-with-us' element={<PublishWithUs />} />
<Route exact path='/publishing-partnership' element={<PublishingPatnership />} />
<Route exact path='/about-us' element={<AboutUs />} />
<Route exact path='/blog' element={<Blog />} />
<Route exact path='/blog/:blogName' element={<SingleBlog />} />
<Route exact path='/contact-us' element={<ContactUs />} />
<Route exact path='/editorial-management' element={<EditorialManagement />} />
<Route exact path='/editorial-management/:editorialName' element={<PostDetails />} />
<Route exact path="/pages"  element={<PagesTest/>} />
<Route exact path="/pages/:pageName"  element={<Pages/>} />
<Route exact path="/articles"  element={<Articles/>} />

</Routes>
<Footer />
</div>

  );
}



export default connect()(App);
