import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
const PublishWithUs = ({dispatch}) => {

  return (
    <>
    <Helmet>   
        <title>
        Publish With Us 
        </title>       
      </Helmet>
      <div>
        PublishWithUs
      </div>
    </>
  );
};

export default connect() (PublishWithUs);
