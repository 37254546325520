import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
const PublishingPatnership = ({dispatch}) => {

  return (
    <>
    <Helmet>   
        <title>
        Publishing Patnership
        </title>       
      </Helmet>
      <div>
        PublishingPatnership
      </div>
    </>
  );
};

export default connect() (PublishingPatnership);
