import React, { useState, useRef, useEffect } from "react";
import { connect } from 'react-redux';
import AboutUsImg from '../assets/screens/about-us_hero.jpg';
import '../styles/screens/AboutUs.css';
import Img from '../assets/screens/our_mission_1.png';
import publishImg from '../assets/screens/publish_0.svg';
import authorImg from '../assets/screens/authors_0.svg';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faArrowRight, faContactCard, faFileAlt, faFileContract, faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";
import aboutUsImg1 from '../assets/screens/aboutUs1.png';
import aboutUsImg2 from '../assets/screens/aboutUs2.jpg';
import aboutUsImg3 from '../assets/screens/aboutUs3.png';
import aboutUsImg4 from '../assets/screens/aboutUs4.png';
import abousUsImg from '../assets/screens/aboutUsImg.jpg';
import axios from "axios";
const AboutUs = ({dispatch}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
     
    async function fetchTrackApiData() {
      const formData = new FormData();
      formData.append("lang","en")
          
      try {
        const response = await axios.post(
          `https://app.xperttext.com/api/get-about-us-page`,
          formData,
          {
          headers: {
          "Content-Type": "multipart/form-data"
          },
          }
         
        );
  
        await console.log(response.data);
        setData(response.data)
        
      } catch (error) {
        await console.error(error);
        // window.alert("Can't Assign Same Track Name")
      }
    }
    fetchTrackApiData();
  }, [1]);

 if(data === null){
  return(
    <div style={{height:2000,textAlign:'center',paddingTop:50}}>
            Loading....
    </div>
  )
 }

  return (
    <>
      <Helmet>   
        <title>
        {data.header_title}
        </title>       
      </Helmet>
      {/* <div className="px-xl-5 pb-5 " style={{paddingTop:0}}>
        <div className="aboutUs">
        <div className="textInImg">
          <div className="route"></div>
          <h1 className="heading">
          {data.header_title}
          </h1>
          <div className="description">
            {data.header_sub_title}
          </div>
          <Link to={data.header_button_link} className="d-flex justify-content-between align-items-center" style={{padding:20,background:'#00A4B7',borderRadius:8,width:'50%',
    textAlign:'left',color:'white',fontSize:18,border:'none',fontWeight:500,textDecoration:'none'}}>
      <div>
      {data.header_button_title}
        </div>
        <div>
          <FontAwesomeIcon icon={faPlayCircle} />

        </div>
    </Link>
        </div>
        </div>

       
      </div> */}
      <div className="aboutUsGetToKnowUs blockResponsiveWidth p-0 pb-lg-5 mt-5">
        <div className="aboutUsGetToKnowUsLeft">
          <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
        <div className="GetToKnowUsSubTitle">
            {data.section_one_title}
          </div>

          <h2 className="GetToKnowUsTitle">
          {data.section_one_sub_title}
          </h2>
          <div className="aboutUsImgPara">
          {data.section_one_description}
         </div>
         <div style={{marginTop:20}} className="d-flex">
          <div className="mr-3">
            <FontAwesomeIcon style={{color:'#00A4B7',width:50,height:50}} icon={faFileAlt} />
          </div>
          <div>
          <h4>
              {data.section_one_sub1_title}
          </h4>
          <div className="aboutUsImgPara" style={{marginTop:0}}>
            {data.section_one_sub1_description}
          </div>
          </div>
         </div>

         <div style={{marginTop:20}} className="d-flex">
         <div className="mr-3">
            <FontAwesomeIcon style={{color:'#00A4B7',width:50,height:50}} icon={faFileAlt} />
          </div>
          <div>
          <h4>
              {data.section_one_sub2_title}
          </h4>
          <div className="aboutUsImgPara" style={{marginTop:0}}>
              {data.section_one_sub2_description}
             </div>
         </div>
          </div>
        </div>
        <div className="aboutUsGetToKnowUsRight">
        {/* abousUsImg */}
          <img src={`https://app.xperttext.com/${data.section_one_image}`}/>
        </div>
      </div>
      <div className="bgCont">
          <div className="bgContoverlay">
            <div className="bgTextCont">
              <div>
            <h2 className="bgText">
                Our Mission is to Protect your Business & Much More
             </h2>
             <div className="bgButtonCont">
              <button className="bgButton">
                  Free Consultation
              </button>
                
             </div>
              </div>
            </div>
          </div>
      </div>
      <div className="blockResponsiveWidth aboutUsBlock2Heading pb-lg-5 pb-3">
      <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
        <div className="GetToKnowUsSubTitle">
            {data.section_two_title}
          </div>

          <h2 className="GetToKnowUsTitle" style={{marginBottom:0}}>
          {data.section_two_sub_title}
          </h2>
      </div>
      <div className="blockResponsiveWidth d-md-flex align-items-center p-0 pb-lg-5 " style={{color: '#333333'}}>
        <div className="w-100 w-md-50">
        {/* aboutUsImg1 */}
            <img src={`https://app.xperttext.com/${data.section_two_image}`} className="img-fluid aboutUsImg"  style={{width:'100%'}}/>
        </div>
        <div className="w-100 w-md-50 p-4">
           {/* <h2 className="aboutUsImgHeading">Give back day</h2> */}
           <p className="aboutUsImgPara">
              {data.section_two_description}
           </p>
           {/* <p className="aboutUsImgPara">
           Keith dedicated so much of his life to giving back to charities and the community, so to honour this memory, we planned a day where we can all give something back to our local communities or to charities close to our hearts. 
           </p> */}
        </div>

      </div>

      <div className="blockResponsiveWidth d-md-flex align-items-center p-0 pb-lg-5 mt-5" style={{color: '#333333'}}>
        <div className="w-100 w-md-50 d-block d-md-none">
          {/* aboutUsImg2 */}
            <img src={`https://app.xperttext.com/${data.section_three_image}`} className="img-fluid aboutUsImg"  style={{width:'100%'}}/>
        </div>
        <div className="w-100 w-md-50 p-4">
           {/* <h2 className="aboutUsImgHeading">Wear Red Day</h2> */}
           <p className="aboutUsImgPara">
            {data.section_three_description}
           </p>
           
        </div>
        <div className="w-100 w-md-50 d-none d-md-block">
          {/* aboutUsImg2 */}
            <img src={`https://app.xperttext.com/${data.section_three_image}`} className="img-fluid aboutUsImg"  style={{width:'100%'}}/>
        </div>

      </div>

      <div className="blockResponsiveWidth aboutUsBlock2Heading pb-lg-5">
      <div className="d-flex mb-2">
            <div className="mr-2" style={{width:25,height:3,background:'#305F8B'}}>

            </div>
            <div style={{width:25,height:3,background:'#305F8B'}}>

            </div>
          </div>
        <div className="GetToKnowUsSubTitle">
            {data.section_four_title}
          </div>

          <h2 className="GetToKnowUsTitle" style={{marginBottom:0}}>
             {data.section_four_sub_title}
          </h2>
      </div>
      
      <div className="blockResponsiveWidth d-md-flex align-items-center" style={{color: '#333333'}}>
        <div className="w-30">
        {/* aboutUsImg3 */}
            <img src={`https://app.xperttext.com/${data.section_four_sub1_img}`} className="img-fluid" />
        </div>
        <div className="w-100 w-md-70 p-4">
           <h2 className="aboutUsImgHeading">{data.section_four_sub1_title}</h2>
           <p className="aboutUsImgPara">
            {data.section_four_sub1_description}
           </p>
        </div>

      </div>

      <div className="blockResponsiveWidth d-md-flex align-items-center " style={{color: '#333333'}}>
        <div className=" w-30 d-block d-md-none">
          {/* aboutUsImg4 */}
            <img src={`https://app.xperttext.com/${data.section_four_sub2_image}`} className="img-fluid" />
        </div>
        <div className="w-100 w-md-70 p-4">
           <h2 className="aboutUsImgHeading">{data.section_four_sub2_title}</h2>
           <p className="aboutUsImgPara">
            {data.section_four_sub2_description}
           </p>
           
        </div>
        <div className=" w-30 d-none d-md-block">
           {/* aboutUsImg4 */}
            <img src={`https://app.xperttext.com/${data.section_four_sub2_image}`} className="img-fluid"/>
        </div>

      </div>

      {/* <div className="getStartedCont d-flex justify-content-center p-5">
        <div style={{textAlign:'center',color:'#333333'}}>
          <h2 className="getStartedHeading">{data.section_five_title}</h2>
          <div className="d-flex justify-content-center">
          <p className="getStartedPara">
           {data.section_five_description}
          </p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Link to={data.section_five_button_link} className="getStartedButtton" style={{position:'relative',textDecoration:'none'}}>
              {data.section_five_button}
            <FontAwesomeIcon className="getStartedButttonIcon" icon={faAngleRight}/>
            </Link>
            <div>
            </div>
          </div>
        </div>

      </div> */}
    </>
  );
};

export default connect() (AboutUs);
