import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import '../styles/screens/Journals.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleRight, faAngleUp, faArrowRight, faCommentDots, faHandDots, faListDots} from "@fortawesome/free-solid-svg-icons";
import documentBlack from '../assets/screens/documentBlack.png';
import downloadBlack from '../assets/screens/downloadBlack.png';
import downloadWhite from '../assets/screens/downloadWhite.png';
import downloadBlue from '../assets/screens/downloadBlue.png';
import pdfWhite from '../assets/screens/pdfWhite.png';
import rightArrowBlue from '../assets/screens/rightArrowBlue.png';
import bookBlack from '../assets/screens/bookBlack.png';
import linkedinBlack from '../assets/screens/linkedinBlack.png';
import facebookBlack from '../assets/screens/facebookBlack.png';
import twitterBlack from '../assets/screens/twitterBlack.png';
import mailBlack from '../assets/screens/mailBlack.png';
import threeVerticalDots from '../assets/screens/threeVerticalDots.png';
import { useParams } from 'react-router-dom';
import axios from "axios";   
const PagesTest = () => {
  return (
   <div>
    Pages
   </div>
    
  );
}

export default PagesTest;

